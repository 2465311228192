import React from "react";

export default function ExpandedRequest({request}) {
    return (
        <div className="request-wrapper">
            <div className="request">
                <div className="request__prompt" dangerouslySetInnerHTML={{__html: request.prompt}}></div>
                <div className="request__reply" dangerouslySetInnerHTML={{__html: request.reply}}></div>
            </div>  
        </div>  
    )
}