import React, { useEffect, useState } from "react";
import { setCurrentProject } from "utils/utils";
import ProjectForm from "components/projectForm/projectForm";
import ConfirmDeletion from "components/confirmAction/confirmDeletion";
import { useNavigate } from "react-router-dom";

export default function Project(
    { 
        project,
        removeProject
    }
    ) {
    const [showProjectForm, setShowProjectForm] = useState(false);
    const [name, setName] = useState(project.name);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setName(project.name);
    }, [project.name]);

    function navigateToChat() {
        setCurrentProject(project);
        navigate('/chat');
    }

    function navigateToFiles() {
        setCurrentProject(project);
        navigate('/files');
    }

    function update(e) {
        e.stopPropagation();
        setShowProjectForm(true);
    }

    function openDeletionConfirmation(event) {
        event.stopPropagation();
        setShowDeleteConfirmation(true);
    }
    
    return (
        <li className="resource">
            {showProjectForm &&
                <ProjectForm
                    project={project}
                    setShowProjectForm={setShowProjectForm}
                    setName={setName}
                />
            }
            {showDeleteConfirmation && (
                <ConfirmDeletion
                    onDelete={() => removeProject(project)}
                    item={name}
                    handleClose={() => setShowDeleteConfirmation(false)}
                />
            )}
            <div className="list-item__leading" >{name}</div>
            <button onClick={navigateToFiles} className="list-item__action-button">Dateien</button>
            <button onClick={navigateToChat} className="list-item__action-button">Chat</button>
            <button onClick={update} className="list-item__action-button">Bearbeiten</button>
            <button onClick={openDeletionConfirmation} className="list-item__delete-button">Löschen</button>
        </li>
    );
}