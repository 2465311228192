import React from "react";

import File from "components/file/file";

export default function FileList({ files, removeFile, toggleWithFilter, toggleWithOutFilter, filterWithMetadata, filterWithOutMetadata }) {

    

    return (
        <div>
            <div className="files-page__has-metadata-checkbox-wrapper">
                <label>Nur Dateien mit Metadaten anzeigen</label>
                <input
                    type="checkbox"
                    checked={filterWithMetadata}
                    onChange={toggleWithFilter}
                    className="files-page__has-metadata-checkbox"
                />
                <label>Nur Dateien ohne Metadaten anzeigen</label>
                <input
                    type="checkbox"
                    checked={filterWithOutMetadata}
                    onChange={toggleWithOutFilter}
                    className="files-page__has-metadata-checkbox"
                />
            </div>
            <ul className="resource-list ml-0">
                {files.map((file) => (
                    <File key={file.filename} removeFile={removeFile} file={file} />
                ))}
            </ul>
        </div>
    )
}