import React, { useEffect, useState } from "react";
import { deleteWebsite, currentProject, getCachedProjects, getWebsites, setCurrentProject, noProjectprompt } from "utils/utils.js";
import DropdownMenu from "components/dropdownMenu/dropdownMenu.js";
import WebsiteList from "components/websites/websiteList.js";
import ListSearch from "components/listSearch/listSearch.js";
import WebsiteCrawling from "components/websiteCrawling/websiteCrawling.js";

export default function WebsitesPage() {
    const [websites, setWebsites] = useState();
    const [project, setProject] = useState(currentProject() || {id: null, name: noProjectprompt()});
    const [showWebsiteList, setShowWebsiteList] = useState(websites && websites.length > 0);
    const [allWebsites, setAllWebsites] = useState([]);

    const projects = getCachedProjects();

    useEffect(() => {
        loadWebsites(project)
      }, []);

    function removeWebsite(website) {
        deleteWebsite(website.id);
        setAllWebsites((allWebsites) => allWebsites.filter((w) => w.id !== website.id));
        setWebsites((websites) => websites.filter((w) => w.id !== website.id));
    }

    async function selectProject(selectedProject) {
        if (selectedProject.name === project.name) {
            return
        }
        setCurrentProject(selectedProject);
        setProject(selectedProject);
        await loadWebsites(selectedProject)
    }

    async function loadWebsites(project) {
        if (project.id === null) {
            setWebsites([])
            setShowWebsiteList(false);
            return
        }
        const data = await getWebsites(project)
        setWebsites(data)
        setAllWebsites(data)
        if (data && data.length > 0) {
            setShowWebsiteList(true);
        } else {
            setShowWebsiteList(false);
        }
    }

    async function reloadWebsites() {
        loadWebsites(project)
    }

    return (
        <div>
            <div className="resources-page__header">
                { projects.length > 0 &&
                    <DropdownMenu selectProject={selectProject} projects={projects}/>}
                <p className="resources-page__title">{project.name}</p>
            </div>
            <div className="resources-page__content">
                { projects.length > 0 &&
                    <WebsiteCrawling callback={reloadWebsites}/>
                }
                { showWebsiteList &&
                    <div className="resources-list-wrapper">
                        <ListSearch items={allWebsites} setItems={setWebsites} searchAttr={"url"} matchAttrs={["state"]}/>
                        <WebsiteList websites={websites} setWebsites={setWebsites} removeWebsite={removeWebsite}/>
                    </div>
                }
            </div>
        </div>
    )

}
