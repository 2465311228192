import React from "react";

import { useState, useEffect } from "react";

import { getConversationById } from "utils/utils";

// conversation has messages which have a prompt and a reply

export default function Conversation({conversationId}) {
    const [conversation, setConversation] = useState(null);

    useEffect(() => {
        loadConversation();
    }, [conversationId]);

    async function loadConversation() {
        try {
            const response = await getConversationById(conversationId);
            setConversation(response.data);
        }
        catch (error) {
            // Handle the error here
        }
    }

    return (
        <div className="conversation">
            {conversation && conversation.message_requests && conversation.message_requests.map((message, index) => (
                <div key={index} className="conversation__message">
                    <div className="conversation__prompt">User:  {message.prompt}</div>
                    <div className="conversation__reply" dangerouslySetInnerHTML={{__html: message.reply}}></div>
                </div>
            ))}
        </div>
    )
}