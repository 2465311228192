import React from 'react';

const ErrorBanner = () => {
    return (
        <div id="error-header" className="error-banner">
            Hier könnte ein Fehler stehen
        </div>
    );
};

export default ErrorBanner;
