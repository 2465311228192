import React from "react";
import { useState } from "react";
import { createUser, getMembers, updateMember } from "utils/utils";

export default function UserForm(
    {
        user,
        users,
        setUsers,
        setShowUserForm,
        allUsers,
        setAllUsers,
    }) {
    const [email, setEmail] = useState(initEmail());
    const [role, setRole] = useState(initRole());
    const previousRole = initRole();

    function initEmail() {
        if (user) {
            return user.email;
        }
        return "Email";
    }

    function initRole() {
        if (user) {
            return user.role;
        }
        return "guest";
    }

    function keyPressed(e) {
        if (e.key === "Enter") {
            e.stopPropagation();
            handleSubmit(e);
        }
    }

    async function updateUser() {
        try {
            await updateMember(user.id, role)
            renderUpdatedUsers(role);
        } catch (error) {
            renderUpdatedUsers(previousRole);
        }
    }

    function renderUpdatedUsers(newRole) {
        const updatedUsers = users.map((u) => {
            if (u.id === user.id) {
                return { ...u, email, newRole };
            }
            return u;
        });
        setUsers(updatedUsers);
        const updatedAllUsers = allUsers.map((u) => {
            if (u.id === user.id) {
                return { ...u, email, newRole };
            }
            return u;
        });
        setAllUsers(updatedAllUsers);
    }


    async function inviteUser(email, role) {
        let newUser = await createUser(email, role);
        if (newUser.email) {
            allUsers = allUsers.concat(newUser);
            users = users.concat(newUser);
            setAllUsers(allUsers);
            setUsers(users);
        } else {
            const fetchedUsers = await getMembers();
            newUser = fetchedUsers[fetchedUsers.length - 1];
            setAllUsers(users);
            users = users.concat(newUser);
            setUsers(users);
        }
    }


    async function handleSubmit(e) {
        e.preventDefault();
        if (user) {
            updateUser();
        } else {
            inviteUser(email, role);
        }
        setShowUserForm(false);
    }

    const title = !!user ? "Die Email-Adresse kann nicht geändert werden" : "Email-Adresse";

    return (
        <div className='userForm' onClick={e => {e.stopPropagation()}}>
            <form className="userForm__form" onKeyDown={keyPressed}>
                <h3 className="userForm__header">{"Benutzer hinzufügen"}</h3>
                <input disabled={!!user} title={title} type='text' className="input" placeholder={email} onChange={e => setEmail(e.target.value)}/>
                <div className="userForm__footer">
                    <select className="userForm__role-dropdown" value={role} onChange={e => setRole(e.target.value)}>
                        <option value="guest">Gast</option>
                        <option value="admin">Admin</option>
                    </select>
                    <button className="userForm__save-button" onClick={e => handleSubmit(e)}>{"Speichern"}</button>
                </div>
            </form>
        </div>
    );
}
