import React from 'react';

export default function SpinnerEvent({ state }) {

    const checkmark = document.getElementsByClassName("check")[0];
    if (checkmark) {
        if (state === "Success" && (!checkmark.classList.contains("check-complete"))) {
            setTimeout(function () {
                checkmark.classList.add("check-complete");
                document.getElementsByClassName("fill")[0].classList.add("fill-complete");
            }, 500);
    
            setTimeout(function () {
                document.getElementsByClassName("path")[0].classList.add("path-complete");
                document.getElementsByClassName("check")[0].classList.add("success");
                document.getElementsByClassName("fill")[0].classList.add("success");
            }, 1000);
        } else if (state === "Loading") {
            checkmark.classList.remove("check-complete");
            document.getElementsByClassName("fill")[0].classList.remove("fill-complete");
            document.getElementsByClassName("path")[0].classList.remove("path-complete");
            document.getElementsByClassName("check")[0].classList.remove("success");
            document.getElementsByClassName("fill")[0].classList.remove("success");
        }
    }
    return (
        <div className="checkmark">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" x="0px" y="0px"
                viewBox="0, 0, 100, 100" id="checkmark">
                <g transform="">
                    <circle className="path" fill="none" stroke="#7DB0D5" strokeWidth="2" strokeMiterlimit="5" cx="50" cy="50" r="22"/>
                    <circle className="fill" fill="none" stroke="#7DB0D5" strokeWidth="2" strokeMiterlimit="5" cx="50" cy="50" r="22"/>
                    <polyline className="check" fill="none" stroke="#7DB0D5" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="5" 
                        points="70,35 45,65 30,52  "/>
                </g>
            </svg>
        </div>
)};