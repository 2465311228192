import React from "react";
import { useEffect } from "react";
import { getFile, updateFileMetadata } from 'utils/utils.js';
import { LinearProgress } from "@mui/material";
import ConfirmDeletion from "components/confirmAction/confirmDeletion";
import Popup from "components/popup/popup";
import { Link } from 'react-router-dom';
import DocumentMetadataList from "components/metadata/documentMetadataList";


// metadata has a name a value and 3 booleans: is_indexed, is_context, is_visible

export default function File( { file, removeFile } ) {
    const [completed, setCompleted] = React.useState(file.state == "success" || false);
    const [error, setError] = React.useState(file.state == "failed" || false);
    const [proceessing, setProcessing] = React.useState(file.state == "processing" || file.state == "created" || (!error && !completed));
    const [filename, setFilename] = React.useState(file.filename);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
    const [showMetadataPopup, setShowMetadataPopup] = React.useState(false);
    const [position, setPosition] = React.useState(file.document_metadata.length + 1);
    const [metadata, mutateMetadata] = React.useState(initiateMetadata());
    const [focussedName, setFocussedName] = React.useState('');
    const [errorName, setErrorName] = React.useState(null);

    const hasMetadata = file.document_metadata.length > 0;

    function initiateMetadata() {
        const initMetadata = {};
        let initPosition = 1;
        for (const md of file.document_metadata) {
            initMetadata[md.name] = {
                value: md.value,
                is_indexed: md.is_indexed,
                is_context: md.is_context,
                is_visible: md.is_visible,
                position: initPosition
            }
            initPosition++;
        }
        initMetadata[''] = {
            value: '',
            is_indexed: false,
            is_context: false,
            is_visible: false,
            position: initPosition
        }
        return initMetadata;
    }

    function setMetadata(name, key, value) {
        let newMetadata = { ...metadata };
        newMetadata[name][key] = value;
        mutateMetadata(newMetadata);
    }

    function setMetadataName(oldName, newName, event) {
        event.stopPropagation();
        event.preventDefault();
        if (metadata[newName] && newName !== '') {
            setErrorName(oldName);
            return;
        }
        if (errorName) {
            setErrorName(null);
        }
        let newMetadata = { ...metadata };
        if (newName === '') {
            delete newMetadata[oldName];
            newMetadata[''].position = position-1;
            setPosition(position - 1);
        } else if (oldName === '') {
            newMetadata[newName] = metadata[oldName];
            delete newMetadata[oldName];
            setPosition(position + 1);
            newMetadata[''] = {
                value: '',
                is_indexed: false,
                is_context: false,
                is_visible: false,
                position: position + 1
            }
        } else {
            newMetadata[newName] = metadata[oldName];
            delete newMetadata[oldName];
        }
        setFocussedName(newName);
        mutateMetadata(newMetadata);
    }

    function metadataToList() {
        const metadataList = [];
        for (const [name, data] of Object.entries(metadata)) {
            metadataList.push({
                name: name,
                value: data.value,
                is_indexed: data.is_indexed,
                is_context: data.is_context,
                is_visible: data.is_visible,
                position: data.position
            });
        }
        // sort by position
        metadataList.sort((a, b) => a.position - b.position);
        return metadataList;
    }

    function updateMetadata() {
        setShowMetadataPopup(false);
        let newMetadata = metadataToList();
        // delete last element
        newMetadata.pop();
        // remove position attribute
        newMetadata = newMetadata.map((md) => {
            delete md.position;
            return md;
        });
        updateFileMetadata(file.id, newMetadata);
    }

    const isPdf = file.filename.endsWith(".pdf");

    useEffect(() => {
        setFilename(file.filename);
    }, [file.filename]);


    const getUrl = () => {
        getFile(file.id).then((response) => {
            const data = response.data;
            if (data.state == "success") {
                setCompleted(true);
            } else if (data.state=="failed") {
                setError(true);
            } else if (data.state=="processing") {
                setProcessing(true);
            }
            // open file in new tab
            var link = document.createElement('a');
            link.target = '_blank';
            link.setAttribute('rel', 'noopener noreferrer');
            link.href = data.url;
            link.click();

        });
    }

    function openMetadataPopup(event) {
        event.stopPropagation();
        setShowMetadataPopup(true);
    }

    function openDeletionConfirmation(event) {
        event.stopPropagation();
        setShowDeleteConfirmation(true);
    }

    return(
        <li className="list-item-wrapper">
            {showMetadataPopup && (
                <Popup
                    handleClose={() => setShowMetadataPopup(false)}
                    onEnter={updateMetadata}
                >
                    <h2 className="metadata-header">Metadaten</h2>
                    <DocumentMetadataList metadata={metadata} setMetadata={setMetadata} metadataToList={metadataToList} setMetadataName={setMetadataName} focussedName={focussedName} errorName={errorName}/>
                    <div className="metadata-button-wrapper">
                        <button onClick={updateMetadata} className="metadata-save-button">Speichern</button>
                    </div>
                </Popup>
            )}
            {showDeleteConfirmation && (
                    <ConfirmDeletion
                        onDelete={() => removeFile(file)}
                        item={filename}
                        handleClose={() => setShowDeleteConfirmation(false)}
                    />
                )}
            <div className="list-item" >
                <div className="list-item__leading" >{filename}</div>
                <Link className='list-item__action-button link-button' to="/chunks" state={{ file_id: file.id, name: filename }} >Chunks</Link>
                { isPdf && <button className="list-item__action-button" onClick={getUrl}>Lesen</button>}
                <button className={"list-item__action-button" + (hasMetadata ? " has-metadata" : "")} onClick={openMetadataPopup}>Metadaten</button>
                <button className="list-item__action-button" onClick={getUrl}>Lesen</button>
                <button className="list-item__delete-button" onClick={openDeletionConfirmation}>Löschen</button>
            </div>
            {error &&
                <div className="progressbar__error"></div>
            }
            {completed &&
                <div className="progressbar__completed"></div>
            }{proceessing &&
                <LinearProgress variant="indeterminate" sx={{
                    height: "0.5rem",
                    borderRadius: "0.25rem",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    marginTop: "0.5rem",
                    transition: "none"
                }}/>
            }
        </li>
    )
}