import React from 'react';
import DocumentMetaData from './documentMetadata';

function DocumentMetadataList({ metadata, setMetadata, metadataToList, setMetadataName, focussedName, errorName}) {

    return (
        <div className='metadata-list-wrapper'>
            {metadataToList().map((documentMetadata) => (
                <DocumentMetaData name={documentMetadata.name} key={documentMetadata.name} metadata={metadata} setMetadata={setMetadata} setMetadataName={setMetadataName} focussed={documentMetadata.name === focussedName} error={documentMetadata.name === errorName} />
            ))}
        </div>
    );
};

export default DocumentMetadataList;