import React from "react";

import { useEffect, useState, useRef } from "react";

// props are expected to have the following properties:
// handleOpen: function to call when the popup is opened
// handleClose: function to call when the popup is closed
// children: the content of the popup
export default function Popup(props) {
    const [position, setPosition] = useState(null);
    const popupContentRef = useRef();
    const resizeObserver = new ResizeObserver(updatePosition);

    useEffect(() => {
        handleOpenPopup();
        updatePosition();
    }, [props.onEnter, props, props.children, props.handleOpen, props.handleClose]);

    useEffect(() => {
        resizeObserver.observe(popupContentRef.current);
    }, [popupContentRef]);

    function updatePosition() {
        // get popup div height and width, as well as document scrolltop and scrollleft values
        // then center the popup in the window
        const popup = document.querySelector('.popup');
        if (!popup) {
            return;
        }
        const popupParent = popup.parentElement;
        const isParentAbsolute = window.getComputedStyle(popupParent).position === 'absolute';
        if (!popupParent) {
            return;
        }
        const popupParentRect = popupParent.getBoundingClientRect();
        const popupRect = popup.getBoundingClientRect();
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        let left = ((windowWidth) - popupRect.width) / 2 + scrollLeft;
        let top = (windowHeight - popupRect.height) / 2 + scrollTop;
        if (isParentAbsolute) {
            left -= popupParentRect.left;
            top -= popupParentRect.top;
        }
        setPosition({ left, top });
    }

    function handleOpenPopup() {
        if (props.handleOpen) {
            props.handleOpen();
        }
        document.addEventListener('click', handleClick);
        window.addEventListener('keydown', handleKeyDown);
    }

    function handleKeyDown(event) {
        if (event.keyCode === 27) { // 27 is the keycode for the ESC key
            handleClose(event);
        } else if (props.onEnter && event.keyCode === 13) {
            props.onEnter.call();
        }
    }

    function handleClick(event) {
        event.stopPropagation();
        const isInsidePopup = event.target.closest('.popup');
        if (!isInsidePopup) {
            handleClose(event);
        }
    }

    function handleClose() {
        resizeObserver.disconnect();
        if (props.handleClose) {
            props.handleClose();
        }
        document.removeEventListener('click', handleClick);
        window.removeEventListener('keydown', handleKeyDown);
    }


    return (
        <div className='popup' ref={popupContentRef} style={position}>
            <div className='popup__header'>
                <button className='popup__close-button' onClick={handleClose}>x</button>
            </div>
            {position && <div className='popup__content'>
                {props.children}
            </div>}
        </div>
    );
}