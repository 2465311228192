import React from "react";
import Source from "components/source/source.js";

export default function ChatSidebar({ context }) {
    let listItems
    if (context) {
        listItems = context.map(source =>
            <Source key={source.score} source={source} />
        );
    }
      
    return (
        <div className="chat-sidebar">
            { context &&
                <ul className="context-wrapper">
                    {listItems}
                </ul>
            }
        </div>
    );

};