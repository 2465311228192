import React, { useEffect } from 'react';
import { useState } from 'react';

import FileUpload from 'components/fileUpload/FileUpload.js';
import Chat from 'components/chat/Chat.js';
import ProjectHeader from 'components/projectHeader/projectHeader.js';
import { getCachedProjects, currentProject, initCache, greeter, noProjectprompt } from 'utils/utils.js';

export default function Workspace({projects, setProjects}) {
    const [project, setProject] = useState(currentProject() || projects[0] || {name: ""});
    const [reply, setReply] = useState(greeter())

    useEffect(() => {
        const dataRes = async () =>
        await initCache()
            .then( () => {  
                setProjects(getCachedProjects() || []);
                setProject(currentProject() || {name: " "});
            })
        dataRes();
      }, []);

    return (
        <div className='workspace-wrapper'>
            <ProjectHeader projects={projects} setProjects={setProjects} project={project} setProject={setProject} setReply={setReply}/>
            {projects.length > 0 ?
                <div className='workspace'>
                    <FileUpload className={"fileupload"}/>
                    <Chat reply={reply} setReply={setReply}/>
                </div>
                :
                <div className='workspace'>
                    <p className='workspace__prompt'>{noProjectprompt() + "Du kannst alternativ auch den Button oben rechts benutzen"}</p>
                </div>
            }
        </div>
    );
}
