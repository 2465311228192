import React from "react";

import Project from "components/project/project.js";
import OpenProjectFormButton from "components/projectForm/openProjectFormButton.js";
import ListSearch from "components/listSearch/listSearch";
import { setCurrentProject, deleteProject, currentProject, getCachedProjects, cacheProjects } from "utils/utils";

export default function ProjectsPage({ projects, setProjects, allProjects, setAllProjects }) {
    function removeProject(project) {
        deleteProject(project.id);
        const newProjects = allProjects.filter(p => p.id !== project.id);
        setAllProjects(newProjects);
        cacheProjects(newProjects);
        const filteredProjects = projects.filter(p => p.id !== project.id);
        setProjects(filteredProjects);
        if (newProjects.length === 0) {
            setCurrentProject(null);
        } else if (currentProject().id === project.id) {
            setCurrentProject(getCachedProjects()[0]);
        }
    }

    return (
        <div className="resources-page">
            <div className="resources-page__header">
                <p className="resources-page__title">Projekte</p>
                <OpenProjectFormButton projects={projects} setProjects={setProjects} allProjects={allProjects} setAllProjects={setAllProjects} />
            </div>
            <div className="resources-page__content">
                <ListSearch items={allProjects} setItems={setProjects} searchAttr="name" />
                {allProjects && 
                    <ul className="resource-list">
                        {projects.map((project) => (
                            <Project
                                key={project.name}
                                project={project}
                                removeProject={removeProject}
                            />
                        ))}
                    </ul>
                }
            </div>
        </div>
    );
}