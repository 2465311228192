import React from 'react';
import { useState } from 'react';
import { cacheProjects, getProjects, getMe, setCurrentProject, setToken, cacheMe, removeToken, clearError } from 'utils/utils';
import User from 'datatypes/user';
import Organization from 'datatypes/organization';

export default function Login({ setProjects, setisAuthenticated }) {
    const [tokenValue, setTokenValue] = useState();
    const [showError, setShowError] = useState(false);


    const handleSubmit = async e => {
        e.preventDefault();
        setToken(tokenValue);
        setShowError(false);
        clearError()
        await getMe().then(async (response) => {
            if (response === undefined) {
                setShowError(true);
                removeToken()
                return;
            } else if (response.status === 401) {
                setShowError(true);
                removeToken()
                return;
            }
            else {
                const user = new User(response.data.id, response.data.user.email, response.data.role, new Organization(response.data.organization.id, response.data.organization.name));
                cacheMe(user);
                await getProjects().then((projects) => {
                    setisAuthenticated(true);
                    setCurrentProject(projects[0]);
                    cacheProjects(projects);
                    setProjects(projects);
                });
            };
        }).catch((error) => {
            setShowError(true);
            removeToken()
        }
        );
    }

    return(
        <div>
            <form className='login'>
                <label>
                    <h2>Authentifizierung</h2>
                    <input placeholder='Authentifizierungstoken' className="input" type="text" onChange={e => setTokenValue(e.target.value)}/>
                </label>
                <div>
                    <button className="button" onClick={handleSubmit}> Bestätigen</button>
                </div>
                {showError && <p className='invalid-token'>Das Token ist ungültig</p>}
            </form>
        </div>
    )
}
