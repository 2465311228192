import React from "react";

import { useState, useEffect } from "react";

import { getOrganization, createEmailTemplate, updateEmailTemplate, getCachedMe } from "utils/utils";

export default function Settings() {
    const [organization, setOrganization] = useState(null);
    const [emailTemplate, setEmailTemplate] = useState(null);
    const [body, setBody] = useState("");
    const [subject, setSubject] = useState("");
    const [editable, setEditable] = useState(false);
    const user = getCachedMe();

    if (!user) {
        window.location.reload();
    }


    useEffect(() => {
        getOrganization(user.organization.id).then((response) => {
            const org = response.data;
            setOrganization(org);
            if (org.email_template) {
                setEmailTemplate(org.email_template);
                setBody(org.email_template.body);
                setSubject(org.email_template.subject);
            }
        });
    }
    , []);

    function saveTemplate() {
        if (body.includes("{token}")) {
            if (emailTemplate) {
                updateEmailTemplate(organization.id, emailTemplate.id, subject, body).then((response) => {
                    const template = response.data;
                    setEmailTemplate(template);
                    setEditable(false);
                });
            } else {
                createEmailTemplate(organization.id, subject, body).then((response) => {
                    const template = response.data;
                    setEmailTemplate(template);
                    setEditable(false);
                });
            }
        } else {
            alert("Das Template muss den Platzhalter {token} enthalten");
        }
    }
    
    return (
        <div className="option-page">
            <div className="option-page__header">
                <h1>Settings</h1>
            </div>
            <div className="option-page__content">
                    <div className="option-page__email-template-form">
                        <div>Betreff</div>
                        <input disabled={!editable} className="option-page__email-template-form-subject-input" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                        <div>Nachricht</div>
                        <textarea disabled={!editable} className="option-page__email-template-form-body-input" value={body} onChange={(e) => setBody(e.target.value)} />
                        {editable ? <button className="option-page__email-template-form-button"  onClick={saveTemplate}>Speichern</button> :
                        <button className="option-page__email-template-form-button" onClick={() => setEditable(true)}>Bearbeiten</button>}
                    </div> 
            </div>
        </div>
    );


}