import React from 'react';


export default function DocumentMetaData({ name, metadata, setMetadata, setMetadataName, focussed, error }) {
    const nameInput = React.useRef(null);

    React.useEffect(() => {
        if (focussed) {
            nameInput.current.focus();
        }
    }
    , []);
    return (
        <div className="metadata-wrapper">
            <label htmlFor="metadata-name">Name</label>
            <input
                id="metadata-name"
                type="text"
                value={name}
                onChange={(e) => setMetadataName(name, e.target.value, e)}
                ref={nameInput}
            />
            {error && <p className="metadata-error">Name existiert bereits</p>}
            <label htmlFor="metadata-value">Wert</label>
            <input
                id="metadata-value"
                type="text"
                value={metadata[name].value}
                onChange={(e) => setMetadata(name, "value", e.target.value)}
            />
            <div className="metadata-checkboxes">
                <label htmlFor="metadata-is-indexed">Soll für die Suche genutzt werden</label>
                <input
                    id="metadata-is-indexed"
                    type="checkbox"
                    checked={metadata[name].is_indexed}
                    onChange={(e) => setMetadata(name, "is_indexed", e.target.checked)}
                />
            </div>
            <div className="metadata-checkboxes">
                <label htmlFor="metadata-is-context">Soll für das Sprachmodell zugänglich gemacht werden</label>
                <input
                    id="metadata-is-context"
                    type="checkbox"
                    checked={metadata[name].is_context}
                    onChange={(e) => setMetadata(name, "is_context", e.target.checked)}
                />
                </div>
            <div className="metadata-checkboxes">
                <label htmlFor="metadata-is-visible">Soll im Chat in der Seitenleiste sichtbar sein</label>
                <input
                    id="metadata-is-visible"
                    type="checkbox"
                    checked={metadata[name].is_visible}
                    onChange={(e) => setMetadata(name, "is_visible", e.target.checked)}
                />
            </div>
        </div>
    );
}