import React, { useEffect, useState } from 'react';

import { GrMoney } from "react-icons/gr";
import { AiOutlineComment } from "react-icons/ai";

import { round } from 'utils/utils';

export default function LLMStats({ llm_requests }) {
    const [inputToken, setInputToken] = useState([]);
    const [outputToken, setOutputToken] = useState([]);
    const [totalTokens, setTotalTokens] = useState(0);
    const [inputCost, setInputCost] = useState(0);
    const [outputCost, setOutputCost] = useState(0);
    const [totalCost, setTotalCost] = useState(0);

    useEffect(() => {
        if (!llm_requests) {
            return;
        }
        let inputToken = 0;
        let outputToken = 0;
        let totalTokens = 0;
        let inputCost = 0;
        let outputCost = 0;
        let totalCost = 0;
        for (const request of llm_requests) {
            inputToken += request.prompt_tokens;
            outputToken += request.completion_tokens;
            totalTokens += request.total_tokens;
            inputCost += request.input_cost;
            outputCost += request.output_cost;
            totalCost += request.total_cost;
        }
        setInputToken(inputToken);
        setOutputToken(outputToken);
        setTotalTokens(totalTokens);
        setInputCost(inputCost);
        setOutputCost(outputCost);
        setTotalCost(totalCost);

    }, []);
    if (!llm_requests) {
        return null;
    }
    return (
        <div>
            <div className='llm-stats__container'>
                <div>
                    <p>Input Tokens: {inputToken}</p>
                    <AiOutlineComment size={50} />
                </div>
                <div>
                    <p>Output Tokens: {outputToken}</p>
                    <AiOutlineComment size={50} />
                </div>
                <div>
                    <p>Total Tokens: {totalTokens}</p>
                    <AiOutlineComment size={50} />
                </div>
                <div>
                    <p>Input Cost: {round(inputCost * 100, 3)} Ct</p>
                    <GrMoney size={50} />
                </div>
                <div>
                    <p>Output Cost: {round(outputCost * 100, 3)} Ct</p>
                    <GrMoney size={50} />
                </div>
                <div>
                    <p>Total Cost: {round(totalCost * 100, 3)} Ct</p>
                    <GrMoney size={50} />
                </div>
            </div>
            <table className='llm-stats__table'>
                <thead>
                    <tr className='llm-stats__table-row'>
                        <th className='llm-stats__table-header--query'>Query</th>
                        <th className='llm-stats__table-header--reply'>Reply</th>
                        <th className='llm-stats__table-header'>Model</th>
                        <th className='llm-stats__table-header'>Provider</th>
                        <th className='llm-stats__table-header'>Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {llm_requests.map((request, index) => (
                        <tr className='llm-stats__table-row' key={index}>
                            <td className='llm-stats__table-data--query'>{request.query}</td>
                            <td className='llm-stats__table-data--reply'>{request.reply}</td>
                            <td className='llm-stats__table-data'>{request.model}</td>
                            <td className='llm-stats__table-data'>{request.provider}</td>
                            <td className='llm-stats__table-data'>{round(request.total_cost * 100, 3)} Ct</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};