import React from 'react';

import { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import { de } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";


import { getMessageRequests, getProjectStats, round, noProjectprompt, setCurrentProject, getCachedProjects, currentProject } from 'utils/utils';
import LLMStats from 'components/dashboard/LLMStats';
import Context from 'components/dashboard/context';
import ProjectStats from 'components/dashboard/projectStats';
import DropdownMenu from 'components/dropdownMenu/dropdownMenu';
import Request from './request';
import Popup from 'components/popup/popup';
import ExpandedRequest from 'components/dashboard/expandedRequest';
import Conversation from 'components/conversation/conversation';


export default function Dashboard() {
    const [requests, setRequests] = useState([]);
    const [averageRating, setAverageRating] = useState(0);
    const [showStats, setShowStats] = useState(false);
    const [llmRequests, setLLMRequests] = useState([]);
    const [showContext, setShowContext] = useState(false);
    const [requestId, setRequestId] = useState(null);
    const [project, setProject] = useState(currentProject() || {id: null, name: noProjectprompt()});
    const [currentDateEnd, setCurrentDateEnd] = useState(new Date());
    const [currentDateStart, setCurrentDateStart] = useState(new Date(currentDateEnd.getFullYear(), currentDateEnd.getMonth(), 1, 0, 0, 0, 0));
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);
    const [stats, setStats] = useState(null);
    const [request, setRequest] = useState(null);
    const [conversationId, setConversationId] = useState(null);
    const [sortedBy, setSortedBy] = useState("user_email");
    const [sortedAsc, setSortedAsc] = useState(true);

    const projects = getCachedProjects();

    useEffect(() => {
        loadMessages();
        loadStats();
    }, [currentDateStart, currentDateEnd, project]);

    useEffect(() => {
        sortMessages();
    }, [sortedBy, sortedAsc]);

    function sortMessages() {
        const sortedRequests = requests.sort((a, b) => {
            if (sortedBy === 'rating') {
                if (a.rating && b.rating) {
                    return sortedAsc ? a.rating - b.rating : b.rating - a.rating;
                } else if (a.rating) {
                    return -1;
                } else if (b.rating) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                if (sortedAsc) {
                    return a.user_email.localeCompare(b.user_email);
                } else {
                    return b.user_email.localeCompare(a.user_email);
                }
            }
        });
        setRequests([...sortedRequests]);
    }


    async function loadStats() {
        try {
            const response = await getProjectStats(currentDateStart, currentDateEnd, project);
            setStats(response.data);
        }
        catch (error) {
            // Handle the error here
        }
    }


    async function loadMessages() {
        try {
            const response = await getMessageRequests(currentDateStart, currentDateEnd, project);
            setRequests(response.data);
            let totalRating = 0;
            let totalRequestsWithRating = 0;
            response.data.forEach((request) => {
                if (request.rating) {
                    totalRating += request.rating;
                    totalRequestsWithRating++;
                }
            });
            setAverageRating(totalRating / totalRequestsWithRating);
        } catch (error) {
            // Handle the error here
        }
    }

    const handleScroll = () => {
        const tableHeader = document.querySelector('.dashboard__table-header');
        const table = document.querySelector('.dashboard__table');
        const tableRect = table.getBoundingClientRect();
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollPosition >= tableRect.top) {
            tableHeader.classList.add('sticky');
        } else {
            tableHeader.classList.remove('sticky');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function selectStats(event, llm_requests) {
        handleClose();
        event.stopPropagation();
        setShowStats(true);
        setLLMRequests(llm_requests);
        window.addEventListener('keydown', handleKeyDown);
        document.addEventListener('click', handleClick);
    }


    function selectContext(event, request_id) {
        handleClose();
        event.stopPropagation();
        setShowContext(true);
        window.addEventListener('keydown', handleKeyDown);
        setRequestId(request_id);
        document.addEventListener('click', handleClick);
    }

    function handleKeyDown(event) {
        if (event.keyCode === 27) { // 27 is the keycode for the ESC key
            handleClose(event);
        }
    }

    function handleClose() {
        setShowContext(false);
        setShowStats(false);
        setShowDateRangePicker(false);
        setRequest(null);
        setConversationId(null);
        document.removeEventListener('click', handleClick);
        window.removeEventListener('keydown', handleKeyDown);
    }

    function handleClick(event) {
        event.stopPropagation();
        const isInsidePopup = event.target.closest('.popup');
        if (!isInsidePopup) {
            handleClose(event);
        }
    }

    async function selectProject(selectedProject) {
        if (selectProject.name == project.name) {
            return
        }
        setCurrentProject(selectedProject);
        setProject(selectedProject);
    }

    const selectionRange = {
        startDate: currentDateStart,
        endDate: currentDateEnd,
        key: 'selection',
    }

    function handleSelect(range) {
        const newEndDate = new Date(range.selection.endDate);
        newEndDate.setHours(23, 59, 59, 999);
        if (range.selection.startDate !== currentDateStart || newEndDate !== currentDateEnd) {
            setCurrentDateStart(range.selection.startDate);
            setCurrentDateEnd(newEndDate);
        }
    }

    function handleDateRangeClick(event) {
        event.stopPropagation();
        window.addEventListener('keydown', handleKeyDown);
        setShowDateRangePicker(true);
        document.addEventListener('click', handleClick);
    }

    function openRequest(request) {
        handleClose();
        setRequest(request);
    }

    function sortBy(target) {
        setSortedAsc(!sortedAsc);
        setSortedBy(target);
    }

    return (
        <div className="dashboard">
            <div className='dashboard__header'>
                { projects.length > 0 &&
                        <DropdownMenu selectProject={selectProject} projects={projects}/>}
                <div className="dashboard__title">Anfragen im Projekt: {project.name} zwischen {currentDateStart.toLocaleDateString()} und {currentDateEnd.toLocaleDateString()}</div>
                <button className="dashboard__date-range-button" onClick={(event) => handleDateRangeClick(event)}>Zeitraum</button>
                {showDateRangePicker && <div className='date-range-picker popup'>
                    <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} locale={de} months={2}/>
                </div>}
            </div>
            {stats && <ProjectStats stats={stats}/>}
            {request && <Popup handleClose={handleClose}>
                <ExpandedRequest request={request}/>
            </Popup>}
            {showStats && <Popup handleClose={handleClose}>
                <LLMStats llm_requests={llmRequests}/>
            </Popup>}
            {showContext && <Popup handleClose={handleClose}>
                <Context requestId={requestId}/>
            </Popup>}
            {conversationId && <Popup handleClose={handleClose}>
                <Conversation conversationId={conversationId}/>
            </Popup>}
            <table className="dashboard__table">
                <thead>
                    <tr className='dashboard__table-header'>
                        <th className="dashboard__table-header-cell">
                            <div className="dashboard__table-header-cell--sortable">
                                User
                                <div onClick={() => sortBy('user_email')} className="dashboard__sort-icon">
                                    {sortedAsc ? <IoChevronUpOutline/> : <IoChevronDownOutline/>}
                                </div>
                            </div>
                        </th>
                        <th className="dashboard__table-header-cell--sortable">
                            Bewertung
                            {(averageRating ) ? ("(Ø" + round(averageRating, 1) + ")") : ""}
                            <div onClick={() => sortBy('rating')} className="dashboard__sort-icon">
                                {sortedAsc ? <IoChevronUpOutline/> : <IoChevronDownOutline/>}
                            </div>
                        </th>
                        <th className="dashboard__table-header-cell">Kommentar</th>
                        <th className="dashboard__table-header-cell">Anfrage</th>
                        <th className="dashboard__table-header-cell dashboard__table-header-cell--reply">Antwort</th>
                        <th className="dashboard__table-header-cell">Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                    {requests && requests.map((request, index) => (
                        <Request key={"request-"+index} request={request} selectStats={selectStats} selectContext={selectContext} setRequest={openRequest} setConversationId={setConversationId}/>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
