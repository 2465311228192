import React from 'react';

import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { GoStar } from "react-icons/go";
import { GrMoney } from "react-icons/gr";
import { FaUsers } from "react-icons/fa";
import { AiOutlineComment } from "react-icons/ai";

import { round } from 'utils/utils';

function ProjectStats({ stats }){
    return (
        <div className="stats-container">
            <div className="stat">
                <h3>Anfragen</h3>
                <p>{stats.total_requests}</p>
                <IoChatbubbleEllipsesOutline size={60} />
            </div>
            <div className="stat">
                <h3>Bewertung im Durchschnitt</h3>
                <p>{round(stats.average_rating, 1) || 'N/A'}</p>
                <GoStar size={60} />
            </div>
            <div className="stat">
                <h3>Gesamtkosten</h3>
                <p>{round(stats.total_cost , 2)} $</p>
                <GrMoney size={60} />
            </div>
            <div className="stat">
                <h3>Aktive Nutzer</h3>
                <p>{stats.active_users}</p>
                <FaUsers size={60} />
            </div>
            <div className="stat">
                <h3>Kommentare</h3>
                <p>{stats.total_comments}</p>
                <AiOutlineComment size={60} />
            </div>
            <div className="stat">
                <h3>Durchschnittskosten pro Anfrage</h3>
                <p>{round(stats.average_cost * 100, 3)} ct</p>
                <GrMoney size={60} />
            </div>
            
        </div>
    );
};

export default ProjectStats;
