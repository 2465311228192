import React from "react";
import { useState } from "react";

import ProjectForm from "components/projectForm/projectForm";

export default function OpenProjectFormButton({projects, setProjects, setProject, setAllProjects, allProjects}) {
    const [showProjectForm, setShowProjectForm] = useState(false);

    function openForm(e) {
        e.stopPropagation();
        setShowProjectForm(true);
    }

    return (
        <div className="resource-button-wrapper">
            <button className="new-resource-button button" onClick={openForm}>
                Neues Projekt</button>
            {showProjectForm && <ProjectForm
                projects={projects}
                setProjects={setProjects}
                setProject={setProject}
                allProjects={allProjects}
                setAllProjects={setAllProjects}
                setShowProjectForm={setShowProjectForm}
            />}
        </div>
    )
}