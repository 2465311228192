import React, { useEffect, useState } from "react";
import { deleteFile, currentProject, getCachedProjects, getFiles, setCurrentProject, noProjectprompt } from "utils/utils.js";
import DropdownMenu from "components/dropdownMenu/dropdownMenu.js";
import FileList from "components/files/fileList.js";
import FileUpload from "components/fileUpload/FileUpload";
import ListSearch from "components/listSearch/listSearch.js";

export default function FilesPage() {
    const [files, setFiles] = useState();
    const [project, setProject] = useState(currentProject() || {id: null, name: noProjectprompt()});
    const [showFileList, setShowFileList] = useState(files && files.length > 0);
    const [allFiles, setAllFiles] = useState([]);
    const [filterWithMetadata, setFilterWithMetadata] = useState(false);
    const [filterWithOutMetadata, setFilterWithOutMetadata] = useState(false);

    
    const projects = getCachedProjects();

    useEffect(() => {
        loadFiles(project)
      }, []);

    function removeFile(file) {
        deleteFile(file.id);
        setAllFiles((allFiles) => allFiles.filter((f) => f.id !== file.id));
        setFiles((files) => files.filter((f) => f.id !== file.id));
    }

    function toggleWithOutFilter() {
        setFilterWithOutMetadata(!filterWithOutMetadata);
        if (!filterWithOutMetadata) {
            setFilterWithMetadata(false)
            setFiles(allFiles.filter(file => (!file.document_metadata) || file.document_metadata.length == 0));
        } else {
            setFiles(allFiles);
        };
    }

    function toggleWithFilter() {
        setFilterWithMetadata(!filterWithMetadata);
        if (!filterWithMetadata) {
            setFilterWithOutMetadata(false)
            setFiles(allFiles.filter(file => file.document_metadata.length > 0));
        } else {
            setFiles(allFiles);
        };
    }

    async function selectProject(selectedProject) {
        if (selectProject.name == project.name) {
            return
        }
        setCurrentProject(selectedProject);
        setProject(selectedProject);
        await loadFiles(selectedProject)
    }

    async function loadFiles(project) {
        if (project.id === null) {
            setFiles([])
            setShowFileList(false);
            return
        }
        const data = await getFiles(project)
        setFiles(data)
        setAllFiles(data)
        if (data && data.length > 0) {
            setShowFileList(true);
        } else {
            setShowFileList(false);
        }
    }

    async function reloadFiles() {
        loadFiles(project)
    }

    return (
        <div>
            <div className="resources-page__header">
                { projects.length > 0 &&
                    <DropdownMenu selectProject={selectProject} projects={projects}/>}
                <p className="resources-page__title">{project.name}</p>
            </div>
            <div className="resources-page__content">
                { projects.length > 0 &&
                    <FileUpload className={"files-page__upload"} callback={reloadFiles}/>
                }
                { showFileList &&
                    <div className="resources-list-wrapper">
                        <ListSearch items={allFiles} setItems={setFiles} searchAttr={"filename"} matchAttrs={["state"]}/>
                        <FileList
                            files={files}
                            setFiles={setFiles}
                            removeFile={removeFile}
                            filterWithMetadata={filterWithMetadata}
                            toggleWithFilter={toggleWithFilter}
                            filterWithOutMetadata={filterWithOutMetadata}
                            toggleWithOutFilter={toggleWithOutFilter}
                        />
                    </div>
                }
            </div>
        </div>
    )

}