import React from 'react';
import { useState } from 'react';
import { reduceToShortText } from 'utils/utils';

function ConversationComponent({ conversation, currentConversationId, setConversationId, setGeneratedDocument, setMessageRequest }) {
    const [collapsed, setCollapsed] = useState(true);

    const lastReply = conversation.message_requests[conversation.message_requests.length - 1].reply;

    function selectConversation(event) {
        event.stopPropagation();
        if (collapsed) {
            setConversationId(conversation.id);
            setGeneratedDocument(lastReply);
            setCollapsed(false);
        } else if (conversation.id === currentConversationId) {
            setCollapsed(true);
        } else {
            setConversationId(conversation.id);
            setGeneratedDocument(lastReply);
            setMessageRequest(conversation.message_requests[conversation.message_requests.length - 1]);
        }
    }

    function selectReply(event, request) {
        event.stopPropagation();
        setGeneratedDocument(request.reply);
        setConversationId(conversation.id);
        setMessageRequest(request);
    }

    let message_requests = conversation.message_requests;
    if (message_requests.length === 0) {
        return
    } else {
        // sort message requests by date and time in a string
        message_requests = message_requests.sort((a, b) => {
            return a.created_at.localeCompare(b.created_at);
        }
        );
    }

    return (
        <li>
            <h4 onClick={selectConversation} className='hover-pointer'>
                {reduceToShortText(conversation.title, 50)}
            </h4>
            <ol>
                {!collapsed && message_requests.map((request) => (
                    <li key={request.id}>
                        <h3 className='hover-pointer' onClick={(event) => selectReply(event, request)}>Version</h3>
                    </li>
                ))}
            </ol>
        </li>
    );
};

export default ConversationComponent;