import React from "react";
import { LinearProgress } from "@mui/material";

import { getLinks, indexLinks, unindexLinks } from "utils/utils";
import ConfirmDeletion from "components/confirmAction/confirmDeletion";
import { Link } from 'react-router-dom';
// A Link has an Url, an Id and a State

class LinkTree {
    constructor(link) {
        this.link = link;
        this.children = [];
        this.parent = null;
        this.childrenLinks = [];
    }

    insert(link) {
        if (link.url == this.link.url) {
            return true;
        }
        else if (link.url.includes(this.link.url)) {
            for (let child of this.children) {
                if (link.url.includes(child.link.url)) {
                    child.insert(link);
                    this.childrenLinks = this.onlyGetChildrenLinks();
                    return true;
                }
            }
            this.children.push(new LinkTree(link));
            return true;
        } else if (this.link.url.includes(link.url)) {
            if (this.parent == null) {
                const newParent = new LinkTree(link);
                newParent.children.push(this);
                this.parent = newParent;
                return true;
            } else {
                return this.parent.insert(link);
            }
        }
        this.childrenLinks = this.onlyGetChildrenLinks();
        return false;
    }

    getChildrenLinks() {
        const childrenLinks = [this.link];
        if (this.children.length > 0) {
            for (let child of this.children) {
                childrenLinks.push(...child.getChildrenLinks());
            }
        }
        return childrenLinks;
    }

    onlyChangeChildren(event, changeFunction) {
        const childrenLinks = this.onlyGetChildrenLinks();
        changeFunction(event, childrenLinks);
    }

    onlyGetChildrenLinks() {
        return this.getChildrenLinks().filter(link => link.url != this.link.url);
    }

    isLinkInProcessing(link) {
        return ["processing", "queued"].includes(link.state);
    }

    render(checkedLinks, onChange, allOnChange) {
        const disabled = this.isLinkInProcessing(this.link);
        const allDisabled = this.childrenLinks.every(link => this.isLinkInProcessing(link));
        const allSelectionChecked = allDisabled || this.childrenLinks.every(link => (checkedLinks.includes(link)));
        const checked = disabled || checkedLinks.includes(this.link);
        return (
            <li key={this.link.url}>
                <div title={disabled ? "Der Link wird noch indiziert, bitte hab noch ein bisschen Geduld.": ""}>
                    <input type="checkbox" disabled={disabled} checked={checked} onChange={(event) => onChange(event, this.link)} id={this.link.url} name={this.link.url} />
                    <label htmlFor={this.link.url}>{this.link.url}</label>
                </div>
                {this.children.length > 0 &&
                    <div>
                        <div title={allDisabled ? "Die Links werden noch indiziert, bitte hab noch ein bisschen Geduld.": ""}>
                            <input disabled={allDisabled} checked={allSelectionChecked} type="checkbox" onChange={(event) => this.onlyChangeChildren(event, allOnChange)} id={this.link.url + "-children"} name={this.link.url+ "-children"}/>
                            <label htmlFor={this.link.url + "-children"}>Alle {allSelectionChecked ? "abwählen" : "auswählen"}</label>
                        </div>
                        <ul>
                            {this.children.map((child) => child.render(checkedLinks, onChange, allOnChange))}
                        </ul>
                    </div>
                }
            </li>
        )
    }
}


export default function Website( { website, removeWebsite } ) {
    const [completed, setCompleted] = React.useState(website.state == "completed" || false);
    const [error, setError] = React.useState(website.state == "failed" || false);
    const [proceessing, setProcessing] = React.useState(website.state == "processing" || website.state == "created" || (!error && !completed));
    const [showLinksPopup, setShowLinksPopup] = React.useState(false);
    const [links, setLinks] = React.useState(null);
    const [checkedLinks, setCheckedLinks] = React.useState([]);
    const [uncheckedLinks, setUncheckedLinks] = React.useState([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);

    function estimateUrlDepth(url) {
       return (url.match(/\//g)||[]).length;
    }

    async function loadUrls() {
        getLinks(website.id).then((response) => {
            const allLinks = response.data.sort((a, b) =>  estimateUrlDepth(a.url) - estimateUrlDepth(b.url));
            const linkTrees = [new LinkTree(allLinks[0])];
            const cLinks = [];
            const uLinks = [];
            let inserted = false;
            for (let link of allLinks) {
                for (let tree of linkTrees) {
                    if (tree.insert(link)) {
                        inserted = true;
                        break;
                    }
                }
                if (["processing", "success", "queued"].includes(link.state)) {
                    cLinks.push(link);
                } else {
                    uLinks.push(link);
                }
                if (inserted) {
                    inserted = false;
                    continue;
                }
                linkTrees.push(new LinkTree(link));
            }
            setLinks(linkTrees);
            setCheckedLinks(cLinks);
            setUncheckedLinks(uLinks);
        }
        ).catch((error) => {
        });
    }

    async function openLinksPopup(event) {
        event.stopPropagation();
        await loadUrls();
        setShowLinksPopup(true);
        document.addEventListener("click", handleClick);
        document.addEventListener("keydown", handleKeyDown);
    }

    function handleClick(event) {
        event.stopPropagation();
        if (event.target.closest(".popup") == null) {
            handleClose();
        }
    }

    function handleKeyDown(event) {
        if (event.key == "Escape") {
            handleClose();
        } else if (event.key == "Enter") {
            save();
        }
    }

    function handleClose() {
        setShowLinksPopup(false);
        document.removeEventListener("click", handleClick);
        document.removeEventListener("keydown", handleKeyDown);
    }

    function handleCheckboxChange(event, link) {
        const isChecked = event.target.checked;
        if (isChecked) {
            setCheckedLinks(prevCheckedLinks => [...prevCheckedLinks, link]);
            setUncheckedLinks(prevUncheckedLinks => prevUncheckedLinks.filter(item => item.url !== link.url));
        } else {
            setUncheckedLinks(prevUncheckedLinks => [...prevUncheckedLinks, link]);
            setCheckedLinks(prevCheckedLinks => prevCheckedLinks.filter(item => item.url !== link.url));
        }
    }

    function handleCheckboxChangeAll(event, links) {
        const isChecked = event.target.checked;
        if (isChecked) {
            setCheckedLinks(prevCheckedLinks => [...prevCheckedLinks, ...links]);
            setUncheckedLinks(prevUncheckedLinks => prevUncheckedLinks.filter(item => !links.some(link => link.url === item.url)));
        } else {
            setUncheckedLinks(prevUncheckedLinks => [...prevUncheckedLinks, ...links]);
            setCheckedLinks(prevCheckedLinks => prevCheckedLinks.filter(item => !links.some(link => link.url === item.url)));
        }
    }

    function save() {
        const toIndex = checkedLinks.filter((link) => !["success", "processing", "queued"].includes(link.state)).map((link) => link.id);
        const toUnindex = uncheckedLinks.filter((link) => !["pending", "processing", "queued"].includes(link.state)).map((link) => link.id);
        if (toIndex.length > 0) {
            indexLinks(website.id, toIndex);
        }
        if (toUnindex.length > 0) {
            unindexLinks(website.id, toUnindex);
        }
        handleClose();
    }

    function openDeletionConfirmation(event) {
        event.stopPropagation();
        setShowDeleteConfirmation(true);
    }

    return(
        <li className="list-item-wrapper">
            {showLinksPopup && <div className="popup website__links-popup">
                <div className="popup__content">
                    <h2>Links</h2>
                    <ul>
                        {(!!links && !!checkedLinks) &&
                        links.map((tree) => tree.render(checkedLinks, handleCheckboxChange, handleCheckboxChangeAll))
                        }
                    </ul>
                    <button className="website__links-popup-save-button" onClick={save}>Speichern</button>
                </div>
            </div>}
            {showDeleteConfirmation && (
                <ConfirmDeletion
                    onDelete={() => removeWebsite(website)}
                    item={website.url}
                    handleClose={() => setShowDeleteConfirmation(false)}
                />
            )}
            <div className="list-item" >
                <div className="list-item__leading" >{website.url}</div>
                <Link className='list-item__action-button link-button' to="/chunks" state={{ website_id: website.id, name: website.url }} >Chunks</Link>
                {completed && <button className="list-item__action-button" onClick={(event) => openLinksPopup(event)}>Links</button>}
                <button className="list-item__delete-button" onClick={openDeletionConfirmation}>Löschen</button>
            </div>
            {error &&
                <div className="progressbar__error"></div>
            }
            {completed &&
                <div className="progressbar__completed"></div>
            }{proceessing &&
                <LinearProgress variant="indeterminate" sx={{
                    height: "0.5rem",
                    borderRadius: "0.25rem",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    marginTop: "0.5rem",
                    transition: "none"
                }}/>
            }
        </li>
    )
}