import React from "react";
import { useState } from "react";

export default function DropdownMenu({ projects, selectProject }) {
    const [open, setOpen] = useState(false);

    const handleOpen = (event) => {
        event.stopPropagation();
        
        if (open) {
            document.removeEventListener('click', handleClose);
        } else {
            document.addEventListener('click', handleClose);
        }
        setOpen(!open);
      };

    const handleClose = () => {
        setOpen(false);
        document.removeEventListener('click', handleClose);
    }

    return (
        <div className='projects dropdown'>
            <button className="projects-button button" onClick={handleOpen}>Projekt auswählen</button>
            {open && <ul className="menu">
                {projects.map((project) => {
                    return (
                        <li className="menu-item" key={project.id}>
                            <button onClick={() => selectProject(project)}>{project.name}</button>
                        </li>
                    );
                })}
            </ul>}
        </div>
    );
}