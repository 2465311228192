import React from "react";
import { useState } from 'react';

import ChatWindow from "components/chatWindow/chatWindow.js";
import ChatSidebar from "components/chatSidebar/chatSidebar.js";
import { sendQuery } from "utils/utils.js";


export default function Chat({ setReply, reply }) {
    const [context, setContext] = useState();
    const [status, setStatus] = useState("");


    async function send_query(query) {
        setContext(undefined);
        setReply("");
        setStatus("Loading")
        const json = JSON.stringify({prompt: query})
        let response = await sendQuery(query);
        if (response.status >= 200 && response.status < 300){
            setReply(response.data.reply);
            setContext(response.data.context);
            setStatus("Success")
        } else {
            setStatus("Error")
        }
    };

    return(
        <div className="chat">
            <ChatWindow send_query={send_query} status={status} reply={reply}/>
            <ChatSidebar context={context} />
        </div>
    );
};