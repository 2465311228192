import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import './stylesheets/App.scss';
import './stylesheets/utils.scss';
import LoginForm from './components/LoginForm/LoginForm.js';
import Workspace from 'components/workspace/workspace.js';
import Header from 'components/header/header.js';
import FallbackPage from 'components/fallbackPage/fallbackPage.js';
import ProjectsPage from 'components/projectsPage/projectsPage.js';
import { getCachedProjects, initCache, getCachedUser, getToken, getCachedMe } from 'utils/utils.js';
import FilesPage from './components/files/filesPage.js';
import UsersPage from './components/usersPage/usersPage.js';
import Dashboard from './components/dashboard/dashboard.js';
import ChunksPage from 'components/chunks/chunksPage';
import WebsitesPage from './components/websites/websitesPage.js';
import ErrorBanner from 'components/error/errorBanner.js';
import Settings from 'components/options/optionPage';
import DocumentGenerationPage from 'components/documentGeneration/documentGenerationPage';

function App() {
  // maintenance mode for the understaffed, comment out when issue is resolved
  // return (
  //   <div style={{marginLeft: '20%', marginTop: '5%'}}>
  //     <h1>Wartungsarbeiten!</h1>
  //     <h3>Wir warten grade, daher müssen Sie das leider auch. 😞 </h3>
  //     <p>Wir versuchen ganz schnell, die Probleme zu beheben, damit wieder alles wie gewohnt funtioniert!</p>
  //   </div>
  // )

  const [isAuthenticated, setisAuthenticated] = useState(!!getToken() && !!getCachedMe());
  const [projects, setProjects] = useState(getCachedProjects());
  const [allProjects, setAllProjects] = useState();
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const dataRes = async () => {
      await initCache()
        .then(() => {
          setUsers(getCachedUser() || []);
          setAllUsers(getCachedUser() || []);
          setProjects(getCachedProjects() || []);
          setAllProjects(getCachedProjects() || []);
        });
    }
    if (isAuthenticated) {
      dataRes();
    }
  }, [isAuthenticated]);


  window.addEventListener("dragover", function (e) {
    e.preventDefault();
  }, false);
  window.addEventListener("drop", function (e) {
    e.preventDefault();
  }, false);

  return (
    <div className="App">
      <Sentry.ErrorBoundary fallback={FallbackPage}>
        <ErrorBanner />
        {isAuthenticated ? (
          <Router>
            <Header setisAuthenticated={setisAuthenticated} hasProjects={projects.length > 0} />
            <Routes>
              <Route path="/chat" element={<Workspace projects={projects} setProjects={setProjects} />} />
              <Route path="/projects" element={<ProjectsPage projects={projects} setProjects={setProjects} allProjects={allProjects} setAllProjects={setAllProjects} />} />
              <Route path="/files" element={<FilesPage />} />
              <Route path="/members" element={<UsersPage allUsers={allUsers} users={users} setUsers={setUsers} setAllUsers={setAllUsers} />} />
              <Route path="/websites" element={<WebsitesPage />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/chunks" element={<ChunksPage />} />
              <Route path="/" element={<Dashboard />} />
              <Route path="/generate" element={<DocumentGenerationPage projects={projects} />} />
            </Routes>
          </Router>
        ) : (
          <LoginForm setProjects={setProjects} setisAuthenticated={setisAuthenticated} />
        )}
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default App;
