import React from "react";
import ConfirmDeletion from "components/confirmAction/confirmDeletion";
import { useState } from "react";

import { updateChunk } from "utils/utils";

export default function Chunk({ chunk, removeChunk }) {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [editable, setEditable] = useState(false);
    const [source, setSource] = useState(chunk.source);
    const chunkName = (chunk.filename ? chunk.filename : chunk.link_url) + " page: " + chunk.page.toString()

    function openDeletionConfirmation(event) {
        event.stopPropagation();
        setShowDeleteConfirmation(true);
    }


    function editChunk() {
        setEditable(true);
    }

    function saveChunk() {
        updateChunk(chunk.id, source).then((response) => {
            const new_chunk = response.data;
            setSource(new_chunk.source);
            setEditable(false);
        });
    }

    return (
        <li className="list-item-wrapper" >
            {showDeleteConfirmation && (
                <ConfirmDeletion
                    onDelete={() => removeChunk(chunk)}
                    item={chunkName}
                    handleClose={() => setShowDeleteConfirmation(false)}
                />
            )}
            <div className="list-item">
                <div className="list-item__leading">{chunkName}</div>
                {!editable ?
                    <button className="list-item__action-button" onClick={editChunk}>Edit</button> :
                    <button className="list-item__action-button" onClick={saveChunk}>Speichern</button>
                }
                <button className="list-item__delete-button" onClick={openDeletionConfirmation}>Delete</button>
            </div>
            <div className="list-item-content">
                <textarea disabled={!editable} value={source} onChange={(e) => setSource(e.target.value)} className="chunk-content"/>
            </div>
        </li>
    )
}
