import React, { useState } from 'react';

import Popup from "components/popup/popup";


function ConfirmDeletion({ onDelete, item, handleClose }) {
    const [inputValue, setInputValue] = useState('');
    const [onEnter, setOnEnter] = useState(null);
    const [inputCorrect, setInputCorrect] = useState(inputValue.toLowerCase() === 'löschen');

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setInputCorrect(event.target.value.toLowerCase() === 'löschen');
        if (event.target.value.toLowerCase() === 'löschen') {
            setOnEnter(() => onDelete);
        }
    };

    function handleDelete() {
        if (inputCorrect) {
            onDelete();
        }
    };

    return (
        <div>
            <Popup handleClose={handleClose} onEnter={onEnter}>
                <div className="delete-modal">
                    <h2>{item} löschen</h2>
                    <p>Bist du dir sicher, dass du {item} löschen willst?</p>
                    <p>Gib "löschen" in das Textfeld ein und klicke auf "Löschen".</p>
                    <div className="modal-buttons">
                        <input type="text" value={inputValue} onChange={handleInputChange} />
                        <button onClick={handleDelete} disabled={!inputCorrect}>
                            Löschen
                        </button>
                    </div>
                </div>
            </Popup>
        </div>
    );
}

export default ConfirmDeletion;