import React from "react";

export default function Source({ source }) {

    const sourceReference = source.filename? "Aus der Datei " + source.filename + " Seite "+ source.page : "Von der Webseite " + source.link_url;

    return (
        <li className="source">
            <div className="source-content">{source.source}</div>
            <div className="source-title">{sourceReference}</div>
            <div className="source-score">Quelle passt zu {Math.round(source.score * 100 * 10000)/10000}% zur Frage</div>
        </li>
    )
};