import axios from 'axios';
import ErrorHandler from 'utils/errorHandler';
import Cookies from 'js-cookie';
import User from 'datatypes/user';

export function getToken() {
    const token = Cookies.get('token');
    return token;
}

export function round(number, decimals) {
    return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function setToken(token) {
    Cookies.set('token', token);
}

export function removeToken() {
    Cookies.remove('token');
}

export function clearStorage() {
    localStorage.clear();
}

export const projectsUrl = process.env.REACT_APP_BACKEND_URL + "/api/v1/projects";


export function projectUrl(project=null) {
    if (project==null) {
        project = currentProject()
    }
    return process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project.id;
}

export function generateDocumenturl(project=null) {
    if (project==null) {
        project = currentProject()
    }
    return process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project.id + "/generate_document";
}

export function chatUrl() {
    return process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + currentProject().id + "/chat";
}

export function fileUrl(project=null) {
    if (project==null) {
        project = currentProject()
    }
    return process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project.id + "/files";
}

export function websiteUrl(project=null) {
    if (project==null) {
        project = currentProject()
    }
    return process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project.id + "/websites";
}

export function indexLinksUrl(websiteId, project=null) {
    if (project==null) {
        project = currentProject()
    }
    return websiteUrl() + "/" + websiteId + "/index_links";
}

export function unindexLinksUrl(websiteId, project=null) {
    if (project==null) {
        project = currentProject()
    }
    return websiteUrl() + "/" + websiteId + "/unindex_links";
}

export function linksUrl(websiteId, project=null) {
    if (project==null) {
        project = currentProject()
    }
    return websiteUrl() + "/" + websiteId + "/links";
}

export function chunksUrl(project=null) {
    if (project==null) {
        project = currentProject()
    }
    return process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project.id + "/chunks";
}

export function conversationsUrl(project=null) {
    if (project==null) {
        project = currentProject()
    }
    return process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project.id + "/conversations";
}

export function allConversationsUrl(project=null) {
    if (project==null) {
        project = currentProject()
    }
    return process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project.id + "/conversations";
}


export function adminMessageRequestUrl(project=null) {
    if (project==null) {
        project = currentProject()
    }
    return process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project.id + "/message_requests";
}

export function greeter() {
    return "Frag mich was. Wenn ich Informationen dazu habe, werde ich sie dir gerne mitteilen.";
}

export function noProjectprompt() {
    return "Du hast noch keine Projekte erstellt. Erstelle ein neues Projekt, um Dateien hochzuladen. Gehe dazu auf die Projekte-Seite.";
}

export  function currentProject() {
    const project = localStorage.getItem('project');
    if (project === undefined || project === null || project === "undefined" || project === "null") {
        return null;
    } else {
        return JSON.parse(project);
    }
}

export function setCurrentProject(project) {
    localStorage.setItem('project', JSON.stringify(project));
}

export function getCachedProjects() {
    const projects = localStorage.getItem('projects');
    if (projects !== null && projects !== undefined && projects !== "undefined" && projects !== "null") {
        return JSON.parse(projects);
    } else {
        return [];
    }
}

export function cacheProjects(projects) {
    localStorage.setItem('projects', JSON.stringify(projects));
}

export function cacheUsers(users) {
    localStorage.setItem('users', JSON.stringify(users));
}

export function getCachedUser() {
    const users = localStorage.getItem('users');
    if (users !== null && users !== undefined && users !== "undefined" && users !== "null") {
        return JSON.parse(users);
    } else {
        return [];
    }
}

export function cacheMe(me) {
    localStorage.setItem('user', JSON.stringify(me));
}

export function getCachedMe() {
    const me = localStorage.getItem('user');
    if (me !== null && me !== undefined && me !== "undefined" && me !== "null") {
        return JSON.parse(me);
    } else {
        return null;
    }
}

export function clearMe() {
    localStorage.removeItem('user');
}


export function clearProjectsCache() {
    localStorage.removeItem('project');
    localStorage.removeItem('projects');
}

export function cacheNavigation(page) {
    localStorage.setItem('page', page);
}

export function getNavigation() {
    return localStorage.getItem('page');
}

function serverUnavailable() {
    setError("Der Server konnte nicht erreicht werden. Bitte versuche es später erneut.");
    throw new Error("Server unavailable");
}

export async function deleteFile(fileId, specificErrors = {}) {
    const token = getToken();
    let response = null;
    try {
        response = await axios.delete(fileUrl() + "/" + fileId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function getFile(fileId, specificErrors = {}) {
    const token = getToken();
    let response = null;
    try {
        response = await axios.get(fileUrl() + "/" + fileId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function createProject(projectName, glossary, index_full_document, specificErrors = {}) {
    const token = getToken();
    let response = null;
    try {
        response = await axios.post(projectsUrl, {name: projectName, glossary: glossary, index_full_document: index_full_document}, {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + token,
            },
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response.data;
}

export async function deleteProject(projectId, specificErrors = {}) {
    const token = getToken();
    let response = null;
    try {
        response = await axios.delete(projectsUrl + "/" + projectId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function updateProject(projectId, projectName, glossary, index_full_document, cut_off, specificErrors = {}) {
    const token = getToken();
    let response = null;
    try {
        response = await axios.put(projectsUrl + "/" + projectId, {name: projectName, glossary: glossary, index_full_document: index_full_document, cut_off: cut_off}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response.data;
}

export async function uploadFile(file, specificErrors = {}) {
    const token = getToken();
    const formData = new FormData();
    let response = null;
    formData.append("file", file);
    try {
        response = await axios.post(fileUrl(), formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + token,
            },
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response
}

export async function getFiles(project=null, specificErrors = {}) {
    let files = null;
    let response = null;
    try {
        response = await axios.get(fileUrl(project), {headers: {
            "Authorization": "Bearer " + getToken(),
        },});
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (response.status === 200){
        files = response.data;
    }
    if (!response) {
        serverUnavailable();
    }
    return files;
}

export async function createUser(email, role, specificErrors = {}) {
    const token = getToken();
    let response = null;
    try {
        response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/v1/invitations", {email: email, role: role}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
        });
    }
    catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function getMembers(specificErrors = {}) {
    const token = getToken();
    let members = null;
    let response = null;
    const users = [];
    try {
        response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/members", {headers: {
            "Authorization": "Bearer " + token,
        },});
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (response.status === 200){
        members = response.data;
    }
    if (!response) {
        serverUnavailable();
    }
    for (const member of members) {
        users.push(new User(member.id, member.user.email, member.role));
    }
    return users;
}

export async function sendQuery (query, specificErrors = {}) {
    const token = getToken();
    const json = JSON.stringify({prompt: query})
    let response = null;
    try {
        response = await axios.post(chatUrl(), json, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function getProjects(specificErrors = {}) {
    const token = getToken();
    let projects = null;
    let response = null;
    try {
        response = await axios.get(projectsUrl, {headers: {
            "Authorization": "Bearer " + token,
        },});
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (response.status === 200){
        projects = response.data;
    } else if (response.status === 401) {
        removeToken();
        clearStorage();
        window.location.reload();
    }
    if (!response) {
        serverUnavailable();
    }
    return projects;
};

export async function initCache() {
    const fetchedProjects = await getProjects();
    const fetchedUsers = await getMembers();
    cacheUsers(fetchedUsers);
    cacheProjects(fetchedProjects);
    currentProject() || setCurrentProject(fetchedProjects[0]);
};

export function clearError() {
    const errorHeader = document.getElementById('error-header');
    errorHeader.style.display = 'none';
}

export function setError(message) {
    const errorHeader = document.getElementById('error-header');
    errorHeader.style.display = 'block';
    errorHeader.innerHTML = `
        <span>${message}</span>
        <button id="close-error" style="cursor: pointer;">x</button>
    `;

    const closeButton = document.getElementById('close-error');
    closeButton.addEventListener('click', clearError);
}

export async function getWebsites(project=null, specificErrors = {}) {
    let websites = null;
    let response = null;
    try {
        response = await axios.get(websiteUrl(project), {headers: {
            "Authorization": "Bearer " + getToken(),
        },});
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (response.status === 200){
        websites = response.data;
    }
    if (!response) {
        serverUnavailable();
    }
    return websites;
}

export async function getWebsiteById(id, project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(websiteUrl(project) + "/" + id, {headers: {
            "Authorization": "Bearer " + getToken(),
        },});
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function createWebsite(url, project=null, specificErrors = {}) {
    let response = null;
    const json = JSON.stringify({url: url})
    try {
        response = await axios.post(websiteUrl(project), json, {headers: {
            "Authorization": "Bearer " + getToken(),
            "Content-Type": "application/json",
        },});
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function deleteWebsite(id, project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.delete(websiteUrl(project) + "/" + id, {headers: {
            "Authorization": "Bearer " + getToken(),
        },});
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function getConversations(with_messages=true, project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(allConversationsUrl(project), {
            params: {
                with_messages: with_messages
            },
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function createConversation(project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.post(conversationsUrl(project), {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function getConversationById(id, project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(conversationsUrl(project) + "/" + id, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function getMessageRequests(startDate=null, endDate=null, project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(adminMessageRequestUrl(project), {
            params: {
                start_date: startDate,
                end_date: endDate
            },
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function getMessageRequest(id, project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(adminMessageRequestUrl(project) + "/" + id, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function indexLinks(websiteId, link_ids, project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.post(indexLinksUrl(websiteId, project), JSON.stringify({link_ids: link_ids}), {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function unindexLinks(websiteId, link_ids, project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.patch(unindexLinksUrl(websiteId, project), JSON.stringify({link_ids: link_ids}), { 
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function getLinks(websiteId, project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(linksUrl(websiteId, project), {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function updateFileMetadata(fileId, metadataList, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.patch(fileUrl() + "/" + fileId, JSON.stringify({document_metadata: metadataList}), {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function getProjectStats(startDate=null, endDate=null, project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(projectUrl(project) + "/stats", {
            params: {
                start_date: startDate,
                end_date: endDate
            },
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function updateMember(id, role, specificErrors = {
    403: "Du hast entweder nicht die Berechtigung für diese Aktion oder eines der folgenden Kriterien wäre nicht gegeben: " +
    "1. Es muss immer einen Admin geben. " +
    "2. Du kannst dich nicht selbst degradieren. " +
    "3. Du kannst den Gründer des Accounts nicht degradieren."
}) {
    let response = null;
    try {
        response = await axios.patch(process.env.REACT_APP_BACKEND_URL + "/api/v1/members/" + id, JSON.stringify({role: role}), {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function deleteMember(id, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.delete(process.env.REACT_APP_BACKEND_URL + "/api/v1/members/" + id, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function getMe(specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/members/me", {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export function getOrganization(organization_id, specificErrors = {}) {
    let response = null;
    try {
        response = axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/organizations/" + organization_id, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export function createEmailTemplate(organization_id, subject, body, specificErrors = {}) {
    let response = null;
    try {
        response = axios.post(process.env.REACT_APP_BACKEND_URL + "/api/v1/organizations/" + organization_id + "/email_templates", JSON.stringify({subject: subject, body: body}), {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export function updateEmailTemplate(organization_id, template_id, subject, body, specificErrors = {}) {
    let response = null;
    try {
        response = axios.patch(process.env.REACT_APP_BACKEND_URL + "/api/v1/organizations/" + organization_id + "/email_templates/" + template_id, JSON.stringify({subject: subject, body: body}), {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function deleteChunk(chunkId, specificErrors = {}) {
    const token = getToken();
    let response = null;
    try {
        response = await axios.delete(chunksUrl() + "/" + chunkId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function getChunks(project=null, file_id=null, vector_search=null, website_id=null, page=1, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(chunksUrl(project),  {
            params: {
                file_id: file_id,
                vector_search: vector_search,
                website_id: website_id,
                page: page
            },
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function updateChunk(chunkId, source, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.put(chunksUrl() + "/" + chunkId, {content: source}, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export function truncateString(str, num) {
    if (str.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}

export async function generateDocument(project_ids=[], prompt, conversation_id=null, project=null, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.post(generateDocumenturl(project), JSON.stringify({project_ids: project_ids, prompt: prompt, conversation_id: conversation_id}), {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            }
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export async function downloadAsDocx(project_id, conversationId, message_request_id, specificErrors = {}) {
    let response = null;
    try {
        response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/v1/projects/" + project_id + "/conversations/" + conversationId + "/message_requests/" + message_request_id + "/generate_docx", {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getToken(),
            },
            responseType: 'blob'
        });
    } catch (error) {
        const errorHandler = new ErrorHandler(specificErrors);
        errorHandler.handleError(error);
        response = error.response;
    }
    if (!response) {
        serverUnavailable();
    }
    return response;
}

export function reduceToShortText(text, length=20) {
    if (text.length > length) {
      return text.substring(0, length) + '...';
    }
    return text;
}