import React from "react";
import { useState, useEffect } from "react";
import { downloadAsDocx, generateDocument, noProjectprompt, currentProject, setCurrentProject, getConversations } from "utils/utils";
import DropdownMenu from "components/dropdownMenu/dropdownMenu";
import SpinnerEvent from "components/utils/spinnerEvent.js";
import ConversationComponent from "./conversationComponent";

export default function DocumentGenerationPage({ projects }) {
    const [project_ids, setProjectIds] = useState([]);
    const [prompt, setPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(currentProject() || {id: null, name: noProjectprompt()});
    const [generatedDocument, setGeneratedDocument] = useState('');
    const [conversationId, setConversationId] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [messageRequest, setMessageRequest] = useState(null);

    useEffect(() => {
        refreshConversations();
    }, []);

    const templateProjects = projects.filter((project) => project.index_full_document === true);
    const knowledgeProjects = projects.filter((project) => project.index_full_document !== true);

    useEffect(() => {
        if (currentProject().index_full_document !== true) {
            if (templateProjects.length > 0) {
                setCurrentProject(templateProjects[0]);
                setProject(templateProjects[0]);
            }
        }
    }, [templateProjects]);

    async function refreshConversations() {
        getConversations().then((response) => {
            setConversations(response.data);
        });
    }

    async function downloadDocx() {
        downloadAsDocx(project.id, conversationId, messageRequest.id).then((response) => {
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            const currentConversation = conversations.find((conversation) => conversation.id === conversationId);
            const name = currentConversation.title;
            link.setAttribute('download', `${name}.docx`);
            document.body.appendChild(link);
            link.click();
        });
    }


    const handleProjectSelection = (projectId) => {
        if (project_ids.includes(projectId)) {
            setProjectIds(project_ids.filter((id) => id !== projectId));
        } else {
            if (project_ids.length < 5) {
                setProjectIds([...project_ids, projectId]);
            }
        }
    };

    const handlePromptChange = (event) => {
        setPrompt(event.target.value);
    };

    function selectProject(selectedProject) {
        if (selectedProject.name === project.name) {
            return;
        }
        setCurrentProject(selectedProject);
        setProject(selectedProject);
        setProjectIds([]);
        setGeneratedDocument('');
        setPrompt('');
        setConversationId(null);
        refreshConversations();
    }

    async function generate() {
        setLoading(true);
        setGeneratedDocument('');
        generateDocument(project_ids, prompt, conversationId).then((response) => {
            setGeneratedDocument(response.data.reply);
            setConversationId(response.data.conversation_id);
            setLoading(false);
            refreshConversations();
            setPrompt('');
        });
    }

    function newConversation() {
        setGeneratedDocument('');
        setConversationId(null);
        setPrompt('');
    }

    function onKeyDown(event) {
        if (event.keyCode === 13) {
            generate();
        }
    }

    return (
        <div>
            {
                (templateProjects.length === 0) ? 
                    <p className="document-generation-page__no-projects">Du brauchst wenigstens ein Vorlagenprojekt. Um ein Projekt zum Vorlagenprojekt zu machen, bearbeite das Projekt und setze den Haken</p>
                :
                    <div className="document-generation-page">
                        <div className="resources-page__header document-generation-page__header">
                            <div>
                            { projects.length > 0 &&
                                <DropdownMenu selectProject={selectProject} projects={templateProjects}/>}
                            </div>
                            <p className="document-generation-page__title">{project.name}</p>
                            
                        </div>
                        <div className="document-generation-page__content">
                            <div className="document-generation-page__sidebar document-generation-page__sidebar--left">
                                <p className="document-generation-page__conversation-title">Informationen aus:</p>
                                {knowledgeProjects.map((project) => (
                                    <div key={project.id} className="document-generation-page__project">
                                        <input
                                            type="checkbox"
                                            checked={project_ids.includes(project.id)}
                                            onChange={() => handleProjectSelection(project.id)}
                                            disabled={project_ids.length === 5 && !project_ids.includes(project.id)}
                                            className="document-generation-page__checkbox"
                                        />
                                        <label className="document-generation-page__label">{project.name}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="document-generation-page__workspace">
                                { !!generatedDocument ? 
                                    <div className="document-generation-page__document-preview document-generation-page__document-preview--filled" dangerouslySetInnerHTML={{__html: generatedDocument}}>
                                    </div>
                                :
                                    <div className="document-generation-page__document-preview document-generation-page__document-preview--empty">
                                    { loading ?
                                        <SpinnerEvent state={"loading"}/>
                                    :
                                        <p className="document-generation-page__document-preview-text">Schreibe etwas in die Prompt und drücke auf generieren um ein Dokument zu generieren!</p>
                                    }
                                    </div>
                                }
                                {generatedDocument !== '' && <div> <button
                                    onClick={newConversation}
                                    className="document-generation-page__new-conversation-button"
                                    >
                                    Neues Dokument beginnen
                                </button>
                                <button onClick={downloadDocx} className="document-generation-page__download-button"
                                >
                                    Download als .docx
                                </button>
                                </div>}
                                <div className="document-generation-page__actions">
                                    <input
                                        type="text"
                                        value={prompt}
                                        onChange={handlePromptChange}
                                        onKeyDown={onKeyDown}
                                        placeholder="Was für ein Dokument möchtest du mit welchen Infos generieren?"
                                        className="document-generation-page__prompt-input"
                                    />
                                    <button
                                        onClick={generate}
                                        className="document-generation-page__generate-button"
                                    >
                                        {(generatedDocument === '') ? "Generieren!" : "Ändern!"}
                                    </button>
                                </div>
                            </div>
                            <div className="document-generation-page__sidebar document-generation-page__sidebar--right">
                                <p className="document-generation-page__conversation-title">Dokumente</p>
                                <ul className="document-generation-page__conversations">
                                    {conversations.map((conversation) => (
                                        <ConversationComponent key={conversation.id} currentConversationId={conversationId} conversation={conversation} setConversationId={setConversationId} setGeneratedDocument={setGeneratedDocument} setMessageRequest={setMessageRequest}/>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

