import React from "react";
import { useState, useEffect, useRef } from "react";

import { createProject, cacheProjects, setCurrentProject, updateProject, getCachedProjects } from "utils/utils";
import Popup from "components/popup/popup";

export default function ProjectForm(
    {
        projects,
        setProjects,
        project,
        allProjects,
        setAllProjects,
        setShowProjectForm,
        setName
    }) {
    const [projectName, setProjectName] = useState(project?.name || '');
    const [glossary, setGlossary] = useState(project?.glossary || '');
    const [index_full_document, setIndexFullDocument] = useState(project?.index_full_document || false);
    const [index_full_document_changed, setIndexFullDocumentChanged] = useState(false);
    const [cut_off, setCutOff] = useState(project?.cut_off)
    const [showPopup, setShowPopup] = useState(false);
    const glossaryRef = useRef(null);

    useEffect(() => {
        document.addEventListener('keydown', keyPressed);
        document.addEventListener('click', handleClose);
    }
    , []);

    const handleClose = () => {
        document.removeEventListener('click', handleClose);
        document.removeEventListener('keydown', keyPressed);
        setShowProjectForm(false);
    }


    function keyPressed(e) {
        if (e.key === "Escape") {
            handleClose();
        }
        if (e.key === "Enter") {
            if (e.target !== glossaryRef.current) {
                e.stopPropagation();
                handleSubmit(e);
            }
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (project) {
            setName(projectName);
            project = await updateProject(project.id, projectName, glossary, index_full_document, cut_off);
            // change name of project in cached projects
            projects = getCachedProjects();
            const index = projects.findIndex(p => p.id === project.id);
            projects[index] = project;
            cacheProjects(projects);
        } else {
            project = await createProject(projectName, glossary, index_full_document);
            if (project.name) {
                setCurrentProject(project);
                projects = projects.concat(project);
                allProjects = allProjects.concat(project);
                setAllProjects(allProjects);
                cacheProjects(projects);
                setProjects(projects);
            }
        }
        handleClose();
    }

    function changeIndexFullDocument() {
        setIndexFullDocument(!index_full_document);
        if (project) {
            setIndexFullDocumentChanged(!index_full_document_changed);
            setShowPopup(!index_full_document_changed);
        }
    }

    function closePopup() {
        setShowPopup(false);
    }

    const button_text = project ? "Speichern" : "Erstellen";
    const placeholder = project ? project.name : "Projektname";
    return (
        <div className='resource-form-wrapper' onClick={e => {e.stopPropagation()}}>
            {showPopup && <Popup handleClose={changeIndexFullDocument} >
                <div className="danger-modal">
                    <h1 className="danger-modal__header">Achtung!</h1>
                    <p className="danger-modal__text">Du hast den Projekttyp geändert (Checkbox), das führt dazu, dass alle Dateien und Links erneut indiziert werden!</p>
                    <p className="danger-modal__text">Bist du sicher, dass du fortfahren willst?</p>
                    <div className="danger-modal__buttons">
                        <button onClick={closePopup} className="danger-modal__button danger-modal__button--confirm">Ja, weiter</button>
                        <button onClick={changeIndexFullDocument} className="danger-modal__button danger-modal__button--cancel">Nein, Abbrechen</button>
                    </div>
                </div>
            </Popup>}

            <form onKeyDown={keyPressed} className="project-form">
                <div className="project-form__name">
                    <label className="project-form__label">Projektname:</label>
                    <input type='text' className="input" value={projectName} placeholder={placeholder} onChange={e => setProjectName(e.target.value)}/>
                </div>
                <div className="project-form__name">
                    <label className="project-form__label">So gut müssen Quellen mindestens passen (0.0-1.0):</label>
                    <input type='text' className="input" value={cut_off} onChange={e => setCutOff(e.target.value)}/>
                </div>
                <label className="project-form__label">Glossar:</label>
                <textarea className="project-form__glossary" value={glossary} placeholder="Glossar" onChange={e => setGlossary(e.target.value)} ref={glossaryRef}/>
                <div className="project-form__index-full-document">
                    <label className="project-form__label">Vorlagenprojekt:</label>
                    <input type='checkbox' checked={index_full_document} onChange={changeIndexFullDocument}/>
                </div>
                <button className="project-form__submit" onClick={e => handleSubmit(e)}>{button_text}</button>
            </form>
        </div>
    );
}