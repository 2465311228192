import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom'

import { deleteChunk, currentProject, getCachedProjects, getChunks, setCurrentProject, noProjectprompt, truncateString } from "utils/utils.js";
import DropdownMenu from "components/dropdownMenu/dropdownMenu.js";
import ChunkList from "components/chunks/chunkList.js";
import ListSearch from "components/listSearch/listSearch.js";
import { LinearProgress } from "@mui/material";

export default function ChunksPage() {
    const location = useLocation()
    const vectorSearchInput = useRef();
    const [chunks, setChunks] = useState();
    const [project, setProject] = useState(currentProject() || { id: null, name: noProjectprompt() });
    const [showChunkList, setShowChunkList] = useState(chunks && chunks.length > 0);
    const [allChunks, setAllChunks] = useState([]);
    const [maxPages, setMaxPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [vector_search, setVectorSearch] = useState(null);
    const [file_id, setFileId] = useState(location?.state?.file_id);
    const [website_id, setWebsiteId] = useState(location?.state?.website_id);
    const [filter, setFilter] = useState(location?.state?.name);
    const [searching, setProcessing] = useState(false);

    const projects = getCachedProjects();

    useEffect(() => {
        loadChunks(project);
    }, [filter, currentPage]);

    function removeChunk(chunk) {
        deleteChunk(chunk.id);
        setAllChunks((allChunks) => allChunks.filter((c) => c.id !== chunk.id));
        setChunks((chunks) => chunks.filter((c) => c.id !== chunk.id));
    }

    async function selectProject(selectedProject) {
        if (selectedProject.name === project.name) {
            return;
        }
        setCurrentPage(1);
        setFilter(null);
        setFileId(null);
        setWebsiteId(null);
        clearVectorSearch();
        setCurrentProject(selectedProject);
        setProject(selectedProject);
        await loadChunks(selectedProject);
    }

    async function loadChunks(project) {
        if (project.id === null) {
            setChunks([]);
            setShowChunkList(false);
            return;
        }
        const response = await getChunks(project, file_id, vector_search, website_id, currentPage);
        const fetched_chunks = response.data.items
        setChunks(fetched_chunks);
        setAllChunks(fetched_chunks);
        if (fetched_chunks && fetched_chunks.length > 0) {
            setMaxPages(response.data.total_pages);
            setShowChunkList(true);
        } else {
            setShowChunkList(false);
        }
    }

    function removeFilter() {
        setFilter(null);
        setFileId(null);
        setWebsiteId(null);
    }

    function vectorSearch() {
        setProcessing(true);
        loadChunks(project).then(() => setProcessing(false));
    }

   function handleKeyDown(event) {
        if (event.key === 'Enter') {
            if (document.activeElement === vectorSearchInput.current) {
                vectorSearch();
            }
        }
    }

    function clearVectorSearch() {
        setVectorSearch("");
        vectorSearchInput.current.value = "";
    }
    

    return (
        <div>
            <div className="resources-page__header">
                {projects.length > 0 && <DropdownMenu selectProject={selectProject} projects={projects} />}
                <p className="resources-page__title">{project.name}</p>
            </div>
            
            <div className="chunks-page__pagination">
                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>{"<"}</button>
                <span>{currentPage} / {maxPages}</span>
                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === maxPages}>{">"}</button>
            </div>
            <div className="resources-page__content">
                <div className="files-page__sidebar">
                {filter &&
                    <div>
                        <h3>Filter:</h3>
                        <div className="files-page__sidebar-filter">
                            <div className="chunks-page__filter">
                                {truncateString(filter, 20)} <span className="close-x" onClick={removeFilter}>X</span>
                            </div>
                        </div>
                    </div>}
                    <div className="files-page__sidebar-vector-search-wrapper">
                        <h3>KI-Suche:</h3>
                        <input type="text" className="files-page__sidebar-vector-search-input" onKeyDown={handleKeyDown} ref={vectorSearchInput} placeholder="Semantische Suche" onChange={(e) => setVectorSearch(e.target.value)} />
                        <div className="files-page__sidebar-vector-search-buttons">
                            <button className="files-page__sidebar-vector-search-button" onClick={vectorSearch}>Suchen</button>
                            <button className="files-page__sidebar-vector-clear-button" onClick={clearVectorSearch}>Reset</button>
                        </div>
                        {searching &&
                            <LinearProgress variant="indeterminate" sx={{
                                height: "0.5rem",
                                borderRadius: "0.25rem",
                                backgroundColor: "rgba(0, 0, 0, 0.1)",
                                marginTop: "0.5rem",
                                transition: "none"
                            }}/>
                        }
                    </div>
                </div>
                {showChunkList && (
                    <div className="resources-list-wrapper">
                        <ListSearch items={allChunks} setItems={setChunks} searchAttr={["filename", "source"]} />
                        <ChunkList chunks={chunks} setChunks={setChunks} removeChunk={removeChunk} />
                    </div>
                )}
            </div>
            
        </div>
    );
}
