import React from "react";

export default function Request({request, selectContext, selectStats, setRequest, setConversationId}) {

    function showRequest(event) {
        event.stopPropagation();
        setRequest(request);
    }

    function showConversation(event) {
        event.stopPropagation();
        setConversationId(request.conversation_id);
    }

    return (
        <tr className="dashboard__table-row">
            <td className="dashboard__table-cell">{request.user_email}</td>
            <td className="dashboard__table-cell">{request.rating}</td>
            <td className="dashboard__table-cell">{request.comment}</td>
            <td className="dashboard__prompt dashboard__table-cell">{request.prompt}</td>
            <td className="dashboard__table-cell dashboard__reply-wrapper">
                <div className="dashboard__reply" dangerouslySetInnerHTML={{__html: request.reply}}></div>
                <button className='dashboard__reply--expand-button' onClick={showRequest} >Mehr...</button>
            </td>
            <td className="dashboard__table-cell">
                <div className="dashboard__action-buttons">
                    <button className="dashboard__action-button" onClick={(event) => selectContext(event, request.id)} >Kontext</button>
                    {request.llm_requests.length > 0 && <button className="dashboard__action-button" onClick={(event) => selectStats(event, request.llm_requests)}>Statistik</button>}
                    {request.conversation_id && <button className="dashboard__action-button" onClick={showConversation}>Konversation</button>}
                </div>
            </td>
        </tr>
    )
}