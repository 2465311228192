import React from "react";
import { useState } from 'react';

import { FileUploader } from "react-drag-drop-files";
// import file upload icon from material ui
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import SpinnerEvent from "components/utils/spinnerEvent";
import { uploadFile } from "utils/utils";


const fileTypes = ["PDF", "DOCX"];

function FileUpload({ className, callback }) {
  const [filename, setFilename] = useState([]);
  const [status, setStatus] = useState("");

  const handleFileUpload = async (files) => {
    setStatus("Loading")
    var error;
    if (files.length > 1) {
      var success = true;
      for (let i = 0; i < files.length; i++) {
          try {
              const file = files[i];
              const response = await uploadFile(file, {422: "Eine oder mehrere Dateien wurden bereits hochgeladen, sie werden übersprungen."});
          } catch (e) {
              error = e;
              success = false;
          }
          if (callback) {
              callback.call()
          }
      }
      if (success) {
          setStatus("Success")
      } else {
          throw error;
      }
    } else {
      const file = files[0];
      const response = await uploadFile(file, {422: "Die Datei wurde bereits hochgeladen."});
      if (response.status >= 200 && response.status < 300){ 
        setStatus("Success")
      }
      if (callback) {
        callback.call()
      }
    }
    removeSpinner();
  };

  async function removeSpinner() {
    await new Promise(r => setTimeout(r, 5000));
    setStatus("");
  }
  
  const handleFile = (event) => {
    const [file] = event.target.files;
    const { name: fileName } = file;
    setFilename(fileName);
  };

  const showSpinner = status==="Loading" || status==="Success";

  
  // render a simple input element with an onChange event listener that calls the handleFileUpload function
  return (
    <div className={className}>
      <FileUploader
        multiple={true} accept={fileTypes}
        classes={"file-drop-area"}
        handleChange={handleFileUpload}
        children={
          <div className="upload-wrapper">
            <h4>Datei Hochladen</h4>
            <label className="upload-label">
              Datei(en) zum Hochladen auswählen oder hier hin ziehen.
            </label>
            <CloudUploadIcon className="upload-icon"/>
            {showSpinner && <SpinnerEvent state={status}/>}
          </div>
        }
      />
    </div>
  );
};
export default FileUpload;