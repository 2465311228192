import React, { useState } from "react";
import { useEffect } from 'react';
import SpinnerEvent from "components/utils/spinnerEvent.js";

export default function ChatWindow({ send_query, status, reply }) {
    const [queryValue, setQueryValue] = useState('');

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (queryValue === "") return;
            send_query(queryValue);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [queryValue]);

    return (
        <div className="chat-window">
            <div className="reply-wrapper">
                {status === "Loading" && <SpinnerEvent state={status}/>}
                <div className="reply" dangerouslySetInnerHTML={{__html: reply}}></div>
            </div>
            <div className="chat-input-wrapper">
                <input className="chat-input" type="text" value={queryValue} onChange={e => setQueryValue(e.target.value)}/>
                <button className="chat-button button" type="button" onClick={() => send_query(queryValue)}>Senden</button>
            </div>
        </div>
    );
}