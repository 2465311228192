import React from "react";

import { useState } from "react";

import User from "components/user/user.js";
import UserForm from "components/userForm/userForm.js";
import ListSearch from "components/listSearch/listSearch";
import Popup from "components/popup/popup";

export default function UsersPage({ users, setUsers, allUsers, setAllUsers }) {
    const [user, setUser] = useState(null);
    const [showUserForm, setShowUserForm] = useState(false);

    function updateUser(event, userToUpdate) {
        event.stopPropagation();
        setUser(userToUpdate);
        setShowUserForm(true);
    }

    function newUser(event) {
        event.stopPropagation();
        setShowUserForm(true);
    }

    function handleClose() {
        setShowUserForm(false);
        setUser(null);
    }

    return (
        <div className="resources-page">
            <div className="resources-page__header">
                <p className="resources-page__title">User</p>
                <div className="resource-button-wrapper">
                    <button className="new-resource-button button" onClick={(event) => newUser(event)}>
                        Einladen
                    </button>
                </div>
                {showUserForm &&
                    <Popup 
                        handleClose={handleClose}>
                        <UserForm
                            user={user}
                            users={users}
                            setUsers={setUsers}
                            allUsers={allUsers}
                            setAllUsers={setAllUsers}
                            setShowUserForm={setShowUserForm}/>
                    </Popup>
                }   
            </div>
            <div className="resources-page__content">
                <ListSearch items={allUsers} setItems={setUsers} searchAttr="email" />
                {allUsers && 
                    <ul className="resource-list">
                        {users.map((userToRender) => (
                            <User
                                key={userToRender.email}
                                user={userToRender}
                                updateUser={updateUser}
                                users={users}
                                setUsers={setUsers}
                            />
                        ))}
                    </ul>
                }
            </div>
        </div>
    );
}