import React from "react";
import DropdownMenu from "components/dropdownMenu/dropdownMenu";
import { setCurrentProject, greeter } from "utils/utils";
import OpenProjectFormButton from "components/projectForm/openProjectFormButton";


export default function ProjectHeader({ projects, setProjects, project, setProject, setReply }) {
    
    function selectProject(project) {
        setCurrentProject(project);
        setProject(project);
        setReply(greeter)
    }

    return (
        <div className='project-header'>
            <DropdownMenu projects={projects} selectProject={selectProject}/>
            <h1>{project.name}</h1>
            <OpenProjectFormButton projects={projects} setProjects={setProjects} setProject={setProject}/>
        </div>
    );
}