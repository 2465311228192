import React, { useEffect, useState } from 'react';
import { getMessageRequest, round } from 'utils/utils';

export default function Context({ requestId }) {
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
                const response = await getMessageRequest(requestId);
                if (response) {
                    setMessage(response.data);
                }
        };

        fetchData();
    }, []);

    if (message === null) {
        return null;
    } else {
        return (
            <div>
                <ul className='source-popup__list'>
                    {message.context.map((source, index) => (
                        <li className='source-popup__source' key={index}>
                            {source.source}
                            {source.page && <p>Seite {source.page}</p>}
                            {source.link_url && <p>Von: {source.link_url}</p>}
                            {source.filename && <p>Aus: {source.filename}</p>}
                            {source.score && <p>Übereinstimmung: {round(source.score *100, 2)}%</p>}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
};
