import React from 'react';

const FallbackPage = () => {
    return (
        <div>
            <h1>Ups! Da ist etwas schief gelaufen...</h1>
            <p>Unser System hat den Fehler schon an unsere Entwickler gemeldet. Bestimmt arbeiten sie schon an einer Lösung!</p>
        </div>
    );
};

export default FallbackPage;
