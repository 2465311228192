import React from 'react';
import { removeToken, clearStorage, clearMe } from 'utils/utils';
import { Link } from 'react-router-dom';

export default function Header({ setisAuthenticated, hasProjects }) {
    function Logout() {
        removeToken();
        setisAuthenticated(false);
        clearStorage();
        clearMe();
    }

    return (
        <header className="App-header">
            <Link className='header-item' to="/projects">Projekte</Link>
            {hasProjects && <Link className='header-item' to="/chat">Chat</Link>}
            {hasProjects && <Link className='header-item' to="/generate">Dokumenterstellung</Link>}
            {hasProjects && <Link className='header-item' to="/files">Dateien</Link>}
            {hasProjects && <Link className='header-item' to="/websites">Websites</Link>}
            {hasProjects && <Link className='header-item' to="/chunks">Chunks</Link>}
            {hasProjects && <Link className='header-item' to="/">Dashboard</Link>}
            {hasProjects && <Link className='header-item' to="/settings">Settings</Link>}
            <Link className='header-item' to="/members">User</Link>
            <a className='header-item' href="#" onClick={() => Logout()}>Logout</a>
        </header>
    );
}
