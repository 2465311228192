import React from "react";

import Website from "components/websites/website";

export default function WebsiteList({ websites, removeWebsite }) {

    return (
        <ul className="resource-list ml-0">
            {websites.map((website) => (
                <Website key={website.url} removeWebsite={removeWebsite} website={website} />
            ))}
        </ul>
    )
}