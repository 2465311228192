import React from "react";
import Chunk from "components/chunks/chunk";

export default function ChunkList({ chunks, removeChunk }) {
    return (
        <ul className="resource-list ml-0">
            {chunks.map((chunk) => (
                <Chunk key={chunk.id} removeChunk={removeChunk} chunk={chunk} />
            ))}
        </ul>
    );
}
