import * as Sentry from "@sentry/react";
import { setError } from "./utils";

export default class ErrorHandler {
    errors = {
        400: "Die Anfrage war fehlerhaft. Bitte versuche es später erneut.",
        403: "Du hast entweder keine Berechtigung für diese Aktion oder deine Anfrage war fehlerhaft. Bitte versuche es später erneut.",
        401: "Dein Token ist ungültig. Bitte überprüfe es und versuche es erneut.",
        404: "Es konnte leider nichts gefunden werden.",
        422: "Die Anfrage war fehlerhaft. Bitte versuche es später erneut.",
    }

    constructor(specificErrors = {}) {
        for (const [key, value] of Object.entries(specificErrors)) {
            this.errors[key] = value;
          }
    }

    handleError(error) {
        if (error.response && error.response.status in this.errors) {
            setError(this.errors[error.response.status]);
        } else {
            Sentry.captureException(error);
            alert("Es ist ein unerwarteter Fehler aufgetreten. Dieser wurde umgehend an die Entwickler gesendet. Bitte versuchen Sie es später erneut."); 
        }
    }
}