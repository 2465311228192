import React from "react";
import { useState, useEffect } from "react";

export default function ListSearch({ items, setItems, searchAttr, matchAttrs }) {
    const [search, setSearch] = useState('');
    const [count, setCount] = useState(null);

    useEffect(() => {
        if (items) {
            setCount(items.length);
        }
    }, [items]);

    function searchItems(e) {
        setSearch(e.target.value);
        if (e.target.value.length === 0) {
            setItems(items);
            setCount(items.length);
            return;
        }
        let filteredItems = new Set();
        let filteredSubset = [];
        if (searchAttr.constructor === Array) {
            for (let attr of searchAttr) {
                filteredSubset = items.filter(item => item[attr].toLowerCase().includes(e.target.value.toLowerCase()));
                filteredSubset.forEach(filteredItems.add, filteredItems);
            }
        } else {
            filteredItems = new Set(items.filter(item => item[searchAttr].toLowerCase().includes(e.target.value.toLowerCase())));
        }
        if (matchAttrs) {
            for (let attr of matchAttrs) {
                filteredSubset = items.filter(item => item[attr].toLowerCase() == e.target.value.toLowerCase());
                filteredSubset.forEach(filteredItems.add, filteredItems);
            }
        }
        const array = Array.from(filteredItems);
        setItems(array);
        setCount(array.length);
    }
    if (!items || items.length === 0) {
        return null;
    }
    return (
        <div className="list-search">
            {count && <label className="list-search__label">({count})</label>}
            <input type="text" className="list-search__input" value={search} onChange={searchItems} placeholder="Suche"/>
        </div>
    );
}