import React, { useState } from "react";

import { deleteMember } from "utils/utils";
import ConfirmDeletion from 'components/confirmAction/confirmDeletion';


export default function User(
    { 
        user,
        updateUser,
        setUsers,
        users,
    }
    ) {
    
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    async function deleteUser(event) {
        const usersBeforeDelete = users;
        await deleteMember(user.id).then(() => {
            setUsers(users.filter((u) => u.id !== user.id));
        }
        ).catch(() => {
            setUsers(usersBeforeDelete);
        });
    }

    function openDeletionConfirmation(event) {
        event.stopPropagation();
        setShowDeleteConfirmation(true);
    }
   
    return (
        <li className="list-item-wrapper">
            <div className="list-item">
                <div className="list-item__leading" >{user.email}</div>
                <div className="list-item__leading" >{user.role}</div>
                <button className="list-item__action-button" onClick={(event) => updateUser(event, user)}>Bearbeiten</button>
                {showDeleteConfirmation && (
                    <ConfirmDeletion
                        onDelete={deleteUser}
                        item={user.email}
                        handleClose={() => setShowDeleteConfirmation(false)}
                    />
                )}
                <button className="list-item__delete-button" onClick={openDeletionConfirmation}>Löschen</button>
            </div>
        </li>
    );
}