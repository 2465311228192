import React from "react";
import { useState } from "react";

import { createWebsite } from "utils/utils.js";

export default function WebsiteCrawling({callback}) {
    const [url, setUrl] = useState("");

    async function handleCrawl() {
        const website = await createWebsite(url);
        callback();
    }

    return (
        <div className="website-crawling">
            <p className="website-crawling__title">Nutze eine Website als Quelle</p>
            <input className="website-crawling__input" type="text" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="Website URL"/>
            <button className="website-crawling__button" onClick={handleCrawl}>Crawl</button>
        </div>
    )
}